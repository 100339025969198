import React, {useContext, useEffect, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import axios from 'axios';
import {socialHostName} from '../../../utils/Configuration';
import SweetAlert from 'react-bootstrap-sweetalert';
import IqSmallLoadingIcon from '../../common/IqSmallLoadingIcon';
import OrganisationSocialContext from '../../../context/organisation/OrganisationSocialContext';
import SocialHandles from '../../../model/organisation/social/SocialHandles';
import {useParams} from 'react-router-dom';

const OrganisationEditorSocials: React.FC = () => {

    let { organisationId } = useParams<{organisationId: string}>();

    const { socialHandles, setSocialHandles } = useContext(OrganisationSocialContext);
    const [editedSocialHandles, setEditedSocialHandles] = useState<SocialHandles>({ ...socialHandles });

    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSocialHandlesChange = (event: any) => {
        if (!("organisationId" in editedSocialHandles) || editedSocialHandles?.organisationId === null) {
            setEditedSocialHandles({ 
                ...editedSocialHandles as SocialHandles, 
                [event.target.name]: event.target.value,
                organisationId: organisationId });
        } else {
            setEditedSocialHandles({ ...editedSocialHandles, [event.target.name]: event.target.value });
        }
    };

    useEffect(() => {
        setEditedSocialHandles(socialHandles);
    }, [socialHandles])

    const updateSocialHandles = (e: any) => {
        e.preventDefault();
        setLoading(true);
        axios.post(socialHostName + 'organisation/edit/social-handles', editedSocialHandles)
            .then((response) => {
                setSocialHandles(response.data)
                setSuccess(true)
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }

    return (
        <div className="organisation-card">
            <div className="iq-tx-11 iq-tx-uppercase iq-tx-spacing-1 iq-tx-semibold iq-tx-color-02">
                Social Handles
                                    </div>
            <Form style={{ marginTop: 15 }}>
                <Form.Group controlId="formFacebook">
                    <Form.Label>Facebook</Form.Label>
                    <Form.Control name="facebook" type="text"
                        onChange={handleSocialHandlesChange}
                        value={editedSocialHandles.facebook} />
                </Form.Group>
                <Form.Group controlId="formLinkedIn">
                    <Form.Label>LinkedIn</Form.Label>
                    <Form.Control name="linkedin" type="text"
                        onChange={handleSocialHandlesChange}
                        value={editedSocialHandles.linkedin} />
                </Form.Group>
                <Form.Group controlId="formTwitter">
                    <Form.Label>Twitter (only the handle/tag)</Form.Label>
                    <Form.Control name="twitter" type="text"
                        onChange={handleSocialHandlesChange}
                        value={editedSocialHandles.twitter} />
                </Form.Group>
                <Form.Group controlId="formCrunchbase">
                    <Form.Label>Crunchbase</Form.Label>
                    <Form.Control name="crunchbase" type="text"
                        onChange={handleSocialHandlesChange}
                        value={editedSocialHandles.crunchbase} />
                </Form.Group>
                <Form.Group controlId="formInstagram">
                    <Form.Label>Instagram</Form.Label>
                    <Form.Control name="instagram" type="text"
                        onChange={handleSocialHandlesChange}
                        value={editedSocialHandles.instagram} />
                </Form.Group>
                <Form.Group controlId="formVimeo">
                    <Form.Label>Vimeo</Form.Label>
                    <Form.Control name="vimeo" type="text"
                        onChange={handleSocialHandlesChange}
                        value={editedSocialHandles.vimeo} />
                </Form.Group>
                <Form.Group controlId="formYoutube">
                    <Form.Label>YouTube</Form.Label>
                    <Form.Control name="youtube" type="text"
                        onChange={handleSocialHandlesChange}
                        value={editedSocialHandles.youtube} />
                </Form.Group>
                <Form.Group controlId="formPinterest">
                    <Form.Label>Pinterest</Form.Label>
                    <Form.Control name="pinterest" type="text"
                        onChange={handleSocialHandlesChange}
                        value={editedSocialHandles.pinterest} />
                </Form.Group>
                <Form.Group controlId="formXing">
                    <Form.Label>Xing</Form.Label>
                    <Form.Control name="xing" type="text"
                        onChange={handleSocialHandlesChange}
                        value={editedSocialHandles.xing} />
                </Form.Group>
                {loading ? <IqSmallLoadingIcon />
                    :
                    <Button style={{ marginLeft: "auto", marginTop: 15 }}
                        variant="primary"
                        type="submit"
                        onClick={(e: any) => updateSocialHandles(e)}>
                        Save
                </Button>
                }
            </Form>
            <SweetAlert danger
                show={error}
                title="An error has occurred!"
                onConfirm={() => setError(false)}
                onCancel={() => setError(false)}>
                Please try again later.
                            </SweetAlert>

            <SweetAlert success
                show={success}
                title="Success!"
                onConfirm={() => setSuccess(false)}
                onCancel={() => setSuccess(false)}>
                Social handles saved successfully!
        </SweetAlert>
        </div>
    );
}

export default OrganisationEditorSocials;
