import React, {useEffect, useState} from "react";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import Select from "react-select";
import translate from "../../../i18n/translate";
import {SelectOption} from "../../news/GlobalNews";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import {contractsHostName} from "../../../utils/Configuration";
import {Switch, TreeSelect} from "antd";
import {format} from "d3-format";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import {FilterNewPreset} from "./ContractList";
import {useHistory} from "react-router-dom";
import "./PublicSectorContracts.css";

interface Props {
    filters?: NewSelectedFilters
    search?: FilterNewPreset
}

export interface NewSelectedFilters {
    followed?: boolean,
    searchTerm?: string | null,
    status?: string[]
    cpvCode?: string[],
    buyerName?: string[],
    supplierName?: string[],
    minValue?: number | null,
    maxValue?: number | null,
    minStartDate?: any | null,
    maxStartDate?: any | null,
    minEndDate?: any | null,
    maxEndDate?: any | null,
    minPublishedDate?: any | null,
    maxPublishedDate?: any | null,
    sme?: boolean | null,
    vcse?: boolean | null,
    gbCohNumber?: string | null,
}

export interface FilterRange {
    from?: any,
    to?: any
}

// CPV Hierarchy
export interface CPVHierarchy {
    title: string,
    key: string,
    value: string,
    children?: CPVHierarchy[]
}

export const { SHOW_PARENT} = TreeSelect;
export const CONTRACT_VALUE_OPTIONS = [0, 25000, 50000, 100000, 250000, 500000, 1000000, 5000000, 10000000];

// Depth-First Search of CPV Hierarchy Tree
export const treeDFSPreorderTraverse = function(treeNode: any, cpvCodeAnswerArray: string[] = []) {
    // If root node is null return array
    if (!treeNode) return cpvCodeAnswerArray;

    // Push root value to array
    cpvCodeAnswerArray.push(treeNode.value);

    // Loop through children and recursively call this function, if the treeNode has children
    if (treeNode.children) {
        for (let child of treeNode.children)
            treeDFSPreorderTraverse(child, cpvCodeAnswerArray);
    }
    // Return array
    return cpvCodeAnswerArray;
};

// Search the tree for a matching CPV value and return the matching Node
export const treeDFSSearchForNodeValue: (cpvNode: CPVHierarchy, matchingTitle: string) =>
    (CPVHierarchy | null) = function(cpvNode: CPVHierarchy, matchingValue: string){

    // If the Node value matches what we're looking for then return the node,
    // else check if the node has children, if it does then loop through checking those, if not then return null
    if(cpvNode.value == matchingValue){
        return cpvNode;
    }else if (cpvNode.children != null){
        let i;
        let result: CPVHierarchy | null = null;
        for(i=0; result == null && i < cpvNode.children.length; i++){
            result = treeDFSSearchForNodeValue(cpvNode.children[i], matchingValue);
        }
        return result;
    }
    return null;
}

const ContractFilters: React.FC<Props> = (props: Props) => {
    let history = useHistory();

    const statusOptions: SelectOption[] = [
        {
            value: "planning",
            label: translate("contracts.planning")
        },
        {
            value: "planned",
            label: translate("contracts.planned")
        },
        {
            value: "active",
            label: translate("contracts.tender")
        },
        {
            value: "complete",
            label: translate("contracts.contractawarded")
        },
        {
            value: "cancelled",
            label: translate("contracts.unsuccessful")
        }
    ];

    // New Filters Definition
    const [showFollowed, setShowFollowed] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [statusFilters, setStatusFilters] = useState<string[]>(["active"]);
    const [buyerFilters, setBuyerFilters] = useState<string[]>([]);
    const [supplierFilters, setSupplierFilters] = useState<string[]>([]);
    const [minValue, setMinValue] = useState<number | undefined>(undefined);
    const [maxValue, setMaxValue] = useState<number | undefined>(undefined);
    const [startDateFrom, setStartDateFrom] = useState<string | undefined>(undefined);
    const [startDateTo, setStartDateTo] = useState<string | undefined>(undefined);
    const [endDateFrom, setEndDateFrom] = useState<string | undefined>(undefined);
    const [endDateTo, setEndDateTo] = useState<string | undefined>(undefined);
    const [publishedDateFrom, setPublishedDateFrom] = useState<string | undefined>(undefined);
    const [publishedDateTo, setPublishedDateTo] = useState<string | undefined>(undefined);
    const [smallMediumEnterprises, setSmallMediumEnterprises] = useState<boolean | undefined>(undefined);
    const [VoluntaryCommunitySocialEnterprise, setVoluntaryCommunitySocialEnterprise] = useState<boolean | undefined>(undefined);
    const [gbCompaniesHouseNumber, setGbCompaniesHouseNumber] = useState<string | undefined>(undefined);

    const [buyerOptions, setBuyerOptions] = useState<SelectOption[]>([]);
    const [loadingBuyers, setLoadingBuyers] = useState<boolean>(false);
    const [buyerError, setBuyerError] = useState<boolean>(false);
    useEffect(() => {
        const fetchBuyerFilters = async () => {
            setLoadingBuyers(true);
            await axios.get(contractsHostName + "elastic/buyers")
                .then((r) =>
                    setBuyerOptions(
                        r.data.map((option: string) => {
                            return {
                                value: option,
                                label: option
                            }
                        })
                    )
                )
                .catch(() => setBuyerError(true))
                .finally(() => setLoadingBuyers(false))
        };

        fetchBuyerFilters();
    }, []);

    const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>([]);
    const [loadingSuppliers, setLoadingSuppliers] = useState<boolean>(false);
    const [supplierError, setSupplierError] = useState<boolean>(false);
    useEffect(() => {
        const fetchSupplierFilters = async () => {
            setLoadingSuppliers(true);
            await axios.get(contractsHostName + "elastic/suppliers")
                .then((r) =>
                    setSupplierOptions(
                        r.data.map((option: string) => {
                            return {
                                value: option,
                                label: option
                            }
                        })
                    )
                )
                .catch(() => setSupplierError(true))
                .finally(() => setLoadingSuppliers(false))
        };

        fetchSupplierFilters();
    }, []);

    // Fetch Cpv Code Hierarchy
    const [cpvHierarchyOptions, setCpvHierarchyOptions] = useState<CPVHierarchy[]>([]);
    const [loadingCpvCodesHierarchy, setLoadingCpvCodesHierarchy] = useState<boolean>(false);
    const [cpvHierarchyError, setCpvHierarchyError] = useState<boolean>(false);
    useEffect(() => {
        const fetchCpvCodeHierarchyFilters = async () => {
            setLoadingCpvCodesHierarchy(true);
            await axios.get(contractsHostName + "elastic/cpvcodehierarchy")
                .then(r =>
                    setCpvHierarchyOptions(
                        r.data.map((cpv: CPVHierarchy) => {
                            return {
                                key: cpv.key,
                                value: cpv.value,
                                title: cpv.title,
                                children: cpv.children
                            }
                        })
                    )
                )
                .catch(() => setCpvHierarchyError(true))
                .finally(() => setLoadingCpvCodesHierarchy(false))
        };

        fetchCpvCodeHierarchyFilters();
    }, []);

    const updateBuyerFilters = (selection: SelectOption[]) => {
        setBuyerFilters(selection?.map((option: SelectOption) => option.value));
    };


    const updateSupplierFilters = (selection: SelectOption[]) => {
        setSupplierFilters(selection?.map((option: SelectOption) => option.value));
    };


    const updateStatusFilters = (filters: SelectOption[]) => {
        setStatusFilters(filters?.map((selected: SelectOption) => selected.value));
    };

    const [cpvFilters, setCpvFilters] = useState<string[]>([]);

    // New filters for search
    const buildNewFilters = () => {
        let filters: NewSelectedFilters = {
            followed: showFollowed,
            searchTerm: searchTerm,
            status: statusFilters,
            cpvCode: cpvFilters,
            buyerName: buyerFilters,
            supplierName: supplierFilters,
            minValue: minValue,
            maxValue: maxValue,
            minStartDate: startDateFrom,
            maxStartDate: startDateTo,
            minEndDate: endDateFrom,
            maxEndDate: endDateTo,
            minPublishedDate: publishedDateFrom,
            maxPublishedDate: publishedDateTo,
            sme: smallMediumEnterprises,
            vcse: VoluntaryCommunitySocialEnterprise,
            gbCohNumber: gbCompaniesHouseNumber
        };

        return filters;
    };

    const getFilters = () => {
        return {
            followed: showFollowed,
            searchTerm: searchTerm ? searchTerm : null,
            status: statusFilters,
            cpvCode: cpvFilters,
            buyerName: buyerFilters,
            supplierName: supplierFilters,
            minValue: minValue ? (minValue > 0 ? minValue : null) : null,
            maxValue: maxValue ? (maxValue > 0 ? maxValue : null) : null,
            minStartDate: startDateFrom ? startDateFrom : null,
            maxStartDate: startDateTo ? startDateTo : null,
            minEndDate: endDateFrom ? endDateFrom : null,
            maxEndDate: endDateTo ? endDateTo : null,
            minPublishedDate: publishedDateFrom ? publishedDateFrom : null,
            maxPublishedDate: publishedDateTo ? publishedDateTo : null,
            sme: smallMediumEnterprises ? smallMediumEnterprises : null,
            vcse: VoluntaryCommunitySocialEnterprise ? VoluntaryCommunitySocialEnterprise : null,
            gbCohNumber: gbCompaniesHouseNumber ? gbCompaniesHouseNumber : null
        };
    };

    const clearNewFilters = async () => {
        setShowFollowed(false);
        setSearchTerm("");
        setStatusFilters([]);
        setCpvFilters([]);
        setBuyerFilters([]);
        setSupplierFilters([]);
        setMinValue(-1);
        setMaxValue(-1);
        setStartDateFrom("");
        setStartDateTo("");
        setEndDateFrom("");
        setEndDateTo("");
        setPublishedDateFrom("");
        setPublishedDateTo("");
        setSmallMediumEnterprises(undefined);
        setVoluntaryCommunitySocialEnterprise(undefined);
        setGbCompaniesHouseNumber(undefined);
    };

    // Use Effect Chain to get Filters
    const [success, setSuccess] = useState<boolean>(false);
    useEffect(() => {
        if (props.filters) {
            let f: NewSelectedFilters = props.filters;

            if (f) {
                setShowFollowed(f.followed ? f.followed : false);
                setSearchTerm(f.searchTerm ? f.searchTerm : undefined);
                setBuyerFilters(f.buyerName ? f.buyerName : []);
                setStatusFilters(f.status ? f.status : []);
                setSupplierFilters(f.supplierName ? f.supplierName : []);
                setCpvFilters(f.cpvCode ? f.cpvCode : []);
                setMinValue(f.minValue ? f.minValue : undefined);
                setMaxValue(f.maxValue ? f.maxValue : undefined);
                setStartDateFrom(getDate(f.minStartDate ? f.minStartDate : undefined));
                setStartDateTo(getDate(f.maxStartDate ? f.maxStartDate : undefined));
                setEndDateFrom(getDate(f.minEndDate ? f.minEndDate : undefined));
                setEndDateTo(getDate(f.maxEndDate ? f.maxEndDate : undefined));
                setPublishedDateFrom(getDate(f.minPublishedDate ? f.minPublishedDate : undefined));
                setPublishedDateTo(getDate(f.maxPublishedDate ? f.maxPublishedDate : undefined));
                setSmallMediumEnterprises(f.sme ? f.sme : undefined);
                setVoluntaryCommunitySocialEnterprise(f.vcse ? f.vcse : undefined);
                setGbCompaniesHouseNumber(f.gbCohNumber ? f.gbCohNumber : undefined);
            }
        }
    }, [props.filters]);

    // Get Search Metadata
    const [filterTitle, setFilterTitle] = useState<string>(props.search?.nameOfSearch ? props.search.nameOfSearch : "");
    useEffect(() => {
        if (props.search) {
            let s: FilterNewPreset = props.search;
            if (s) {
                setFilterTitle(props.search.nameOfSearch)
            }
        }
    }, [props.search]);

    // Save Search
    const [showSaveError, setShowSaveError] = useState<boolean>(false);
    const save = async () => {
        if (disableSave()) {
            setShowSaveError(true);
        }
        else {
            let filters = {
                id: props.search?.saveSearchId,
                title: filterTitle,
                filters: buildNewFilters()
            };

            let filtersToSave = getFilters();
            await axios.post(contractsHostName + "mysql/savesearch?nameOfSearch=" + filterTitle, filtersToSave)
                .then(() => {
                    if (filters.id) {
                        deleteOldSearch(filters.id);
                    }
                    setSuccess(true)
                });
        }

    };

    // Delete function to remove old search
    const deleteOldSearch = async (id: number) => {
        await axios.delete(contractsHostName + "mysql/getsavedsearch?searchId=" + id)
            .then(() => setSuccess(true));
    };

    const getDate = (millis: number) => {
        if (!millis) {
            return "";
        }
        let str = new Date(millis).toISOString();
        return str.substr(0, str.indexOf("T"));
    };

    const [count, setCount] = useState<number>();
    const [loadingCount, setLoadingCount] = useState<boolean>(false);
    useEffect(() => {
        const getCount = async () => {
            setLoadingCount(true);
            let newlyRebuiltFilters = buildNewFilters();
            getFilters();
            await axios.post(contractsHostName + "elastic/search/count", newlyRebuiltFilters)
                .then(r => setCount(r.data))
                .finally(() => setLoadingCount(false));
        }
        getCount();
    }, [showFollowed, searchTerm, buyerFilters, supplierFilters, statusFilters, cpvFilters, minValue, maxValue, startDateFrom, startDateTo, endDateFrom, endDateTo, publishedDateFrom, publishedDateTo, gbCompaniesHouseNumber]);

    const disableSave = () => {
        return !hasTitle() || isLoading() || excessiveCount();
    };

    const hasTitle = () => {
        return filterTitle && filterTitle.length > 0
    };

    const isLoading = () => {
        return (loadingCpvCodesHierarchy || loadingBuyers || loadingCount)
    };

    const excessiveCount = () => {
        return (count !== undefined && count > 10000)
    };

    const getSaveErrorMessage = () => {
        if (!hasTitle()) {
            return "Filter must have a title"
        }
        else if (excessiveCount()) {
            return "Filter cannot return more than 10,000 contracts";
        }

        return "Cannot save while loading filters"
    };

    const successHandler = () => {
        setSuccess(false);
        history.push("/public-sector/contracts");
    };

    // Called on change of CPV Selector
    const onChange = (newValue: string[]) => {
        let arrOfNodes: CPVHierarchy[] = [];

        // Loop through values in onChange, then push the node to array
        for (let val of newValue) {
            let returnVal = findCpvTreeNodeFromCode(val);
            if (returnVal) {
                arrOfNodes.push(returnVal);
            }
        }

        // Loop through nodes and gather their DFS results in a new array
        let arrOfValuesToAdd: string[] = [];
        for (let node of arrOfNodes) {
            arrOfValuesToAdd = treeDFSPreorderTraverse(node, arrOfValuesToAdd);
        }

        setCpvFilters(arrOfValuesToAdd);
    };

    // Traverse CPV Hierarchy and search for code value
    const findCpvTreeNodeFromCode = (inputCpvCodeValueOfNode: string) => {

        if (inputCpvCodeValueOfNode === null || cpvHierarchyOptions === null) {
            return null;
        }

        let treeNode: CPVHierarchy | null = null;
        for (const topLevelNode of cpvHierarchyOptions) {
            // Find node in CPV Hierarchy
            treeNode = treeDFSSearchForNodeValue(topLevelNode, inputCpvCodeValueOfNode);
            if (treeNode !== null) {
                break
            }
        }

        if (treeNode === undefined) {
            return null;
        }

        return treeNode;
    }

    // Search Tree
    const searchFilterTreeNode= (search: string, item: any) => {
        return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
    }

    // CPV Tree Props Settings
    const tProps = {
        treeData: cpvHierarchyOptions,
        value: cpvFilters,
        onChange: onChange,
        showSearch: true,
        autoClearSearchValue: true,
        allowClear: true,
        treeCheckable: true,
        bordered: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: 'CPV Codes',
        style: {
            width: '100%',
            height: '100%',
            fontSize: '16px',
            lineHeight: 2,
            borderRadius: '4px'
        },
        filterTreeNode: searchFilterTreeNode
    };



    return (
        <div>
            <div className="filter-container mb-3">
                <Form className="ps-3 pe-3">
                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={2} lg={2} xl={2}>
                            <span className="organisation-card-label">{translate("contracts.filtername")}</span>
                        </Col>
                        <Col xs={8} sm={8} md={4} lg={4} xl={4}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={translate("contracts.contracttitle")}
                                   value={filterTitle}
                                   onChange={(e: any) => setFilterTitle(e.target.value)} />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={2} lg={2} xl={2}>
                            <span className="organisation-card-label">{translate("contracts.searchterm")}</span>
                        </Col>
                        <Col xs={8} sm={8} md={4} lg={4} xl={4}>
                            <input type="text"
                                   className="form-control"
                                   placeholder={translate("advancedsearch.search")}
                                   value={searchTerm}
                                   onChange={(e: any) => setSearchTerm(e.target.value)} />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={2} lg={2} xl={2}>
                            <span className="organisation-card-label">{translate("contracts.status")}</span>
                        </Col>
                        <Col xs={8} sm={8} md={4} lg={4} xl={4}>
                            <Select isMulti
                                    isDisabled={false}
                                    options={statusOptions}
                                    value={
                                        statusFilters?.map((status: string) => statusOptions.find((option: SelectOption) => option.value === status)) as any
                                    }
                                    onChange={(e: any) => updateStatusFilters(e)}
                                    placeholder={translate("contracts.status")}
                                    className="basic-multi-select"
                                    classNamePrefix="select" />
                        </Col>
                    </Row>

                    {/* CPV Code Hierarchy */}
                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={2} lg={2} xl={2}>
                            <span className="organisation-card-label">{translate("contracts.cpvcodes")}</span>
                        </Col>
                        <Col xs={8} sm={8} md={5} lg={5} xl={5}>
                            <TreeSelect {...tProps} disabled={loadingCpvCodesHierarchy || cpvHierarchyError} />
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={2} lg={2} xl={2}>
                            <span className="organisation-card-label">{translate("contracts.buyers")}</span>
                        </Col>
                        <Col xs={8} sm={8} md={5} lg={5} xl={5}>
                            <Select isMulti
                                    isDisabled={loadingBuyers || buyerError}
                                    options={buyerOptions}
                                    value={
                                        buyerFilters?.map((buyer: string) => {
                                            return {
                                                value: buyer,
                                                label: buyer
                                            }
                                        })
                                    }
                                    onChange={(e: any) => updateBuyerFilters(e)}
                                    placeholder={translate("contracts.buyers")}
                                    className="basic-multi-select"
                                    classNamePrefix="select" />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={2} lg={2} xl={2}>
                            <span className="organisation-card-label">{translate("contracts.suppliers")}</span>
                        </Col>
                        <Col xs={8} sm={8} md={5} lg={5} xl={5}>
                            <Select isMulti
                                    isDisabled={loadingSuppliers || supplierError}
                                    options={supplierOptions}
                                    value={
                                        supplierFilters?.map((supplier: string) => {
                                            return {
                                                value: supplier,
                                                label: supplier
                                            }
                                        })
                                    }
                                    onChange={(e: any) => updateSupplierFilters(e)}
                                    placeholder={translate("contracts.suppliers")}
                                    className="basic-multi-select"
                                    classNamePrefix="select" />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={2} lg={2} xl={2}>
                            <span className="organisation-card-label">{translate("contracts.value")}</span>
                        </Col>
                        <Col xs={3} sm={3} md={2} lg={2} xl={2}>
                            <Form.Select className="form-control" value={minValue} onChange={(e: any) => setMinValue(e.target.value)}>
                                <option value={undefined}>{translate("contracts.nomin")}</option>
                                { maxValue ? maxValue > 0 ? (
                                    CONTRACT_VALUE_OPTIONS
                                        .filter((option: number) => option != maxValue && option < maxValue)
                                        .map((opt: number) => (
                                            <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                        ))
                                ) : (
                                    CONTRACT_VALUE_OPTIONS.map((opt: number) => (
                                        <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                    ))
                                ) : (
                                    CONTRACT_VALUE_OPTIONS.map((opt: number) => (
                                        <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                    ))
                                )}
                            </Form.Select>
                        </Col>
                        <Col className="text-center pt-2" xs={2} sm={2} md={1} lg={1} xl={1}>
                            <span>{translate("advancedsearch.to")}</span>
                        </Col>
                        <Col xs={3} sm={3} md={2} lg={2} xl={2}>
                            <Form.Select className="form-control" value={maxValue} onChange={(e: any) => setMaxValue(e.target.value)}>
                                <option value={undefined}>{translate("contracts.nomax")}</option>
                                { minValue ? minValue > 0 ? (
                                    CONTRACT_VALUE_OPTIONS
                                        .filter((option: number) => option != minValue && option > minValue)
                                        .map((opt: number) => (
                                            <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                        ))
                                ) : (
                                    CONTRACT_VALUE_OPTIONS.map((opt: number) => (
                                        <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                    ))
                                ) : (
                                    CONTRACT_VALUE_OPTIONS.map((opt: number) => (
                                        <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                    ))
                                ) }
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={2} lg={2} xl={2}>
                            <span className="organisation-card-label">{translate("contracts.startdate")}</span>
                        </Col>
                        <Col xs={3} sm={3} md={2} lg={2} xl={2}>
                            <input type="date" className="form-control" value={startDateFrom} onChange={(e: any) => setStartDateFrom(e.target.value)}/>
                        </Col>
                        <Col className="text-center pt-2" xs={2} sm={2} md={1} lg={1} xl={1}>
                            <span>{translate("advancedsearch.to")}</span>
                        </Col>
                        <Col xs={3} sm={3} md={2} lg={2} xl={2}>
                            <input type="date" className="form-control" value={startDateTo} onChange={(e: any) => setStartDateTo(e.target.value)}/>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={2} lg={2} xl={2}>
                            <span className="organisation-card-label">{translate("contracts.enddate")}</span>
                        </Col>
                        <Col xs={3} sm={3} md={2} lg={2} xl={2}>
                            <input type="date" className="form-control" value={endDateFrom} onChange={(e: any) => setEndDateFrom(e.target.value)}/>
                        </Col>
                        <Col className="text-center pt-2" xs={2} sm={2} md={1} lg={1} xl={1}>
                            <span>{translate("advancedsearch.to")}</span>
                        </Col>
                        <Col xs={3} sm={3} md={2} lg={2} xl={2}>
                            <input type="date" className="form-control" value={endDateTo} onChange={(e: any) => setEndDateTo(e.target.value)}/>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={2} lg={2} xl={2}>
                            <span className="organisation-card-label">{translate("contracts.publisheddate")}</span>
                        </Col>
                        <Col xs={3} sm={3} md={2} lg={2} xl={2}>
                            <input type="date" className="form-control" value={publishedDateFrom} onChange={(e: any) => setPublishedDateFrom(e.target.value)}/>
                        </Col>
                        <Col className="text-center pt-2" xs={2} sm={2} md={1} lg={1} xl={1}>
                            <span>{translate("advancedsearch.to")}</span>
                        </Col>
                        <Col xs={3} sm={3} md={2} lg={2} xl={2}>
                            <input type="date" className="form-control" value={publishedDateTo} onChange={(e: any) => setPublishedDateTo(e.target.value)}/>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col className="pt-2" xs={4} sm={4} md={2} lg={2} xl={2}>
                            <span className="organisation-card-label">{translate("contracts.contractsfound")}</span>
                        </Col>
                        <Col xs={8} sm={8} md={5} lg={5} xl={5} className="pt-2">
                            {isLoading() ? (
                                <span className="pt-1">
                                    <Spinner style={{display: "block", marginLeft: "auto", marginRight: "auto", height: "1rem", width: "1rem"}}
                                             animation="border"
                                             variant="primary"/>
                                </span>
                            ) : (
                                <span>{(count !== undefined && statusFilters?.length > 0 && count < 10000) ? format(',')(count) : "10,000+"}</span>
                            )}
                        </Col>
                    </Row>
                    <Row className="pull-right pe-1 mb-2">
                        <div className="pt-2 me-3">
                            <span className="pe-2">
                                {translate("contracts.showfollowed")}
                            </span>
                            <span>
                                <Switch checked={showFollowed} onChange={() => setShowFollowed(!showFollowed)} />
                            </span>
                        </div>
                        <button type="button"
                                className="iq-button iq-button-secondary me-2"
                                onClick={() => { setMinValue(-1); setMaxValue(-1); clearNewFilters(); }}>
                            {translate("contracts.clearfilters")}
                        </button>
                        <button type="button" className="iq-button iq-button-primary pull-right" onClick={() => save()} disabled={isLoading()}>
                            <span className="me-2">
                                {translate("teams.manage.savechanges")}
                            </span>
                            <span>
                                <FontAwesomeIcon icon={faSave} />
                            </span>
                        </button>
                    </Row>
                </Form>
            </div>

            <SweetAlert success
                        show={success}
                        title="Success!"
                        onConfirm={successHandler}
                        onCancel={successHandler}>
            </SweetAlert>

            <SweetAlert danger
                        show={showSaveError}
                        title="Unable to save filters"
                        onConfirm={() => setShowSaveError(false)}
                        onCancel={() => setShowSaveError(false)}>
                {getSaveErrorMessage()}
            </SweetAlert>
        </div>
    );
};

export default ContractFilters;