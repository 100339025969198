import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Team} from "./Teams";
import DateFormatter from "../../utils/formatters/DateFormatter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faChevronRight, faHashtag, faTools, faTrashAlt, faUsers} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import translate from "../../i18n/translate";

interface Props {
    team: Team;
    deleteTeam: () => void
}

const TeamLink: React.FC<Props> = (props: Props) => {
    const [team, setTeam] = useState<Team>(props.team);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    let history = useHistory();

    const accept = () => {
        setShowSuccess(false);
        props.deleteTeam();
    };

    const deleteTeam = async (id: number) => {
        await axios.delete(frontEndHostName + "teams/" + id + "/delete")
            .then(r => setShowSuccess(true))
            .catch(error => setShowError(true))
            .finally(() => setShowDelete(false))
    };

    return (
        <>
            <div className="team-card">
                <div>
                    <span className="team-icon-box">
                        <FontAwesomeIcon icon={faUsers} color="white" size="1x" />
                    </span>
                    <span className="name">
                        {team.name}
                    </span>
                </div>
                <div className="team-content-container">
                    <div className="team-details-container">
                        <div className="team-detail">
                            <div className="team-detail-container">
                                <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faCalendar} color="#9120C3" size="1x" /></span>
                                <span>{translate("teams.main.createdon", {date: DateFormatter.formatDate(team.createdDate.toString())})}</span>
                            </div>
                        </div>
                        <div className="team-detail">
                            <div className="team-detail-container">
                                <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faHashtag} color="#f84899" size="1x" /></span>
                                <span>{translate("teams.main.members", {count: team.users.length})}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="team-actions">
                    <div className="team-action">
                        <button className="iqx-button sm-size pull-right" onClick={() => setShowDelete(true)}>
                            <span style={{ paddingRight: 10 }}>{translate("teams.main.deleteteam")}</span>
                            <FontAwesomeIcon icon={faTrashAlt} size="1x" />
                        </button>
                    </div>
                    <div className="team-action">
                        <button className="iqx-button sm-size pull-right" onClick={() => history.push(`/team/${team.id}/edit`)}>
                            <span style={{ paddingRight: 10 }}>{translate("teams.main.manage")}</span>
                            <FontAwesomeIcon icon={faTools} size="1x" />
                        </button>
                    </div>
                    <div className="team-action">
                        <button className="iqx-button sm-size primary mb-2 pull-right" onClick={() => history.push(`/team/${team.id}`)}>
                            <span style={{ paddingRight: 10 }}>{translate("teams.main.viewteam")}</span>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                        </button>
                    </div>

                </div>
            </div>

            <SweetAlert warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                title="Are you sure?"
                onConfirm={() => deleteTeam(team.id)}
                onCancel={() => setShowDelete(false)}
                show={showDelete}
                focusCancelBtn>
                You are about to delete {team.name}.
            </SweetAlert>
            <SweetAlert success
                show={showSuccess}
                title="Success!"
                onConfirm={() => accept()}
                onCancel={() => accept()}>
                Team has been deleted successfully!
            </SweetAlert>
            <SweetAlert danger
                show={showError}
                title="Unable to delete list"
                onConfirm={() => setShowError(false)}
                onCancel={() => setShowError(false)}>
                Please try again later.
            </SweetAlert>
        </>
    )
};

export default TeamLink;
