import React, {ChangeEvent, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Alert, Button, Form, InputGroup, Spinner} from "react-bootstrap";

import './LoginForm.css';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faLock, faUser} from '@fortawesome/free-solid-svg-icons';
import translate from "../../i18n/translate";
import LanguageToggle from "../common/LanguageToggle";
import {getFingerprint, getUserLoginCount} from "../../utils/Security";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import {frontEndHostName} from '../../utils/Configuration';

interface Props {
    setLogged: React.Dispatch<React.SetStateAction<boolean>>
}

const LoginForm: React.FC<Props> = (props: Props) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [activated, setActivated] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [attempt, setAttempt] = useState<number>(0);

    const history = useHistory();

    const LoginLogo = () => {
        return (
            <div id="login-logo">
                <img src="/logo-large.png" alt="logo" />
            </div>
        );
    };


    const handleEnter = (ev: any): any => {
        if (ev.keyCode === 13) {
            handleLogin(ev);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleEnter);

        return () => {
            document.removeEventListener("keydown", handleEnter);
        };
    }, [username, password]);

    const [concurrentSession, setConcurrentSession] = useState<boolean>(false);

    const handleLogin = (e: React.SyntheticEvent) => {
        e.preventDefault();
        login(false);
    };

    const login = (endSession: boolean) => {
        setError(null);
        setLoading(true);

        let config: any = {
            headers: {
                failed_attempt: attempt,
                username: username
            }
        };

        if (endSession) {
            config["params"] = {
                reset: endSession
            }
        }

        axios.post([frontEndHostName, 'authenticateWithNotification'].join(''),
            {
                username: username,
                password: password,
                fingerprint: getFingerprint()
            },
            config
        ).then((response) => {
            if (response.status === 200) {
                localStorage.setItem('login', JSON.stringify({
                    token: response.data.access_token,
                    refresh_token: response.data.refresh_token
                }));
                props.setLogged(true);

                let loginCount = getUserLoginCount();
                if (loginCount > 0) {
                    history.push("/");
                }
                else {
                    history.push("/onboarding");
                }
            }
        }).catch(error => {
            let failedAttempt = error.response.headers["failed_attempt"];
            if (error.response.status === 401) {
                if (failedAttempt > 2) {
                    setError(translate("login.error.maxfailedattempts"));
                }
                else {
                    setError(translate("login.error.incorrectpassword"));
                    setAttempt(failedAttempt);
                }
            }  else if (error.response.status === 403) {
                if (error.response.data.errorKey === "sessionexists") {
                    setConcurrentSession(true);
                }
                else {
                    setActivated(false);
                }
            } else {
                setError(translate("login.error.other"))
            }
        }).finally(() => setLoading(false));
    };

    const showError = () => {
        if (!activated){
            return (
                <Alert variant="danger">
                    {translate("login.error.notactivatedpart1")}
                    <a href="mailto:customersuccess@iqblade.com">customersuccess@iqblade.com</a>
                    {translate("login.error.notactivatedpart2")}
                </Alert>
            )
        }

        return (<Alert variant="danger">
            {error}
        </Alert>)
    };

    const endCurrentSession = () => {
        setConcurrentSession(false);
        login(true);
    };

    return (
        <div id="login-container">
            <div id="login-form-container">
                <LoginLogo />
                <div id="login-form-header">
                    <div className="title">
                        {translate("login.signin")}
                    </div>
                    <div className="description">
                        {translate("login.subtitle")}
                    </div>
                </div>
                <Form id="login-form">
                    <Form.Group controlId="formUserName" className="mb-3">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faUser} color="#3177c8" size="1x" /></InputGroup.Text>
                            <Form.Control autoComplete="off"
                                name="username" type="text" className="login-input" placeholder={translate("login.email")}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => { setUsername(e.target.value) }} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="formPassword" className="mb-3">
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon icon={faLock} color="#3177c8" size="1x" /></InputGroup.Text>
                            <Form.Control autoComplete="off" name="password" type="password" className="login-input" placeholder={translate("login.password")}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => { setPassword(e.target.value) }} />
                        </InputGroup>
                    </Form.Group>
                    {loading &&
                        <div style={{ display: "flex", flexFlow: "center" }}>
                            <Spinner style={{ marginLeft: "auto", marginRight: "auto" }} animation="border" variant="primary" />
                        </div>
                    }
                    {(error != null || !activated) &&
                        <div>
                            {showError()}
                        </div>
                    }
                    <span onClick={() => history.push("/forgot-password")} className="forgot-password">
                        {translate("login.forgotpassword")}
                        <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faChevronRight} color="#3177c8" size="1x" /></span>
                    <span className="remember-me">
                        <Form.Check type="checkbox" label={translate("login.rememberme")} />
                    </span>
                    <Button style={{ marginTop: 35 }} type="submit" variant="primary" onClick={(e: React.MouseEvent<Element, MouseEvent>) => { handleLogin(e) }}>
                        {translate("login.signin")}
                    </Button>
                    <div className="row mb-4 text-center">
                        <LanguageToggle />
                    </div>
                </Form>
            </div>

            <SweetAlert error
                        show={concurrentSession}
                        onConfirm={endCurrentSession}
                        onCancel={() => setConcurrentSession(false)}
                        showConfirm={true}
                        showCancel={true}
                        confirmBtnText={translate("login.error.confirmendsession")}
                        title={translate("login.error.concurrentsession", { user: username })}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "1em",
                    backgroundColor: "white", borderRadius: "0.45rem", padding: 15, width: "100%"
                }}>
                    {translate("login.error.endsession")}
                </div>
            </SweetAlert>
        </div >
    );
}

export default LoginForm;
