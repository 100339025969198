export default {
    jobs: {
        requestJobs: "Request Jobs",
        nojobs: "No Jobs found for given organisation"
    },
    featuredon: {
        title: "Featured On",
        nolists: "This organisation appears in no lists",
        remove: "Remove",
        deleteconfirm: "Are you sure you want to remove this organisation from {list}?",
        error: "Unable to delete from list"
    },
    addtolist: {
        title: "Add To List",
        multipletitle: "Add All To List",
        subtitle: "Add Company To List",
        multiplesubtitle: "Add Companies To List",
        chooselist: "Choose or create a new list",
        listplaceholder: "Select or type to create a new list...",
        selectplaceholder: "Select a list...",
        listlabel: "{title} ({count} companies)",
        addsuccess: "Successfully added to list!",
        duplicatename: "A list with this name already exists!",
        frozen: {
            campaign: "You cannot add organisations because this list is being used in a campaign."
        },
        maxcapacity: "You do not have capacity to add more companies in lists",
        maxlists: "You do not have capacity to create more lists"
    },
    follow: "Follow",
    followed: "Followed",
    followcapacity: "You do not have the capacity to follow more organisations",
    updatecompanyinfo: {
        title: "Update Company Info",
        text: "An update on the company will be completed within 1 business day.",
        popover: "Request to update the company info"
    },
    temporarylink: {
        button: "Share Temporary Link",
        title: "Generate Temporary Link",
        email: "Enter email",
        share: "Share"
    },
    summary: {
        title: "Summary",
        introduction: "Introduction",
        socials: "Social Media",
        tags: "Tags",
        competencies: "Competencies",
        techpartners: "Technology Partnerships",
        companydetails: "Company Details",
        funding: {
            title: "Funding",
            date: "Date",
            round: "Round",
            value: "Value",
            leadinvestor: "Lead Investor",
            type: "Type",
            acquired: "Acquired"
        },
        companyinfo: {
            title: "Company Info",
            website: "Website",
            address: "Office Address",
            phone: "Phone Number",
            financialyearend: "End of Financial Year",
            legalform: "Legal Form",
            investment: "Investment Raised",
            digitalmaturity: "Digital Maturity Score",
            fiscalyearend: "Fiscal Year End",
            ultimateparentcompany: "Ultimate Parent Company",
            immediateparentcompany: "Immediate Parent Company",
            subsidiarycompany: "Subsidiary Company"
        },
        linkedinsearch: {
            poweredby: "Powered By",
            seniorcontacts: "Find Senior Contacts",
            sales: "Find Sales & Marketing Contacts",
            infrastructure: "Find Tech Infrastructure Contacts",
            security: "Find Application, Data, DevOps and Security Contacts"
        },
        classification: {
            sector: "Sector",
            industrygroup: "Industry Group",
            industry: "Industry",
            subindustry: "SubIndustry",
            isic: "ISIC Codes",
            sic: "SIC Codes",
            ussic: "US SIC Codes",
            wz: "WZ Codes",
            naf: "NAF Codes",
            expand: "Expand view",
            isichierarchy: "ISIC Hierarchy"
        },
        headlinenumbers: {
            title: "Headline Numbers",
            revenues: "Revenues",
            employees: "Employees in this organisation"
        },
        filinghistory: {
            name: "Filing History",
            title: "Title",
            date: "Date Filed"
        },
        map: {
            title: "Office Locations",
            locations: "Show all locations"
        }
    },
    financials: {
        title: "Financials",
        notavailable: "Not Available",
        turnover: "Turnover",
        turnoverchange: "1YR Growth",
        investmentraised: "Investment Raised",
        cagr: "3YR Growth",
        grossprofit: "Gross Profit",
        operatingprofit: "Operating Profit",
        weeks: "Weeks",
        currency: "Currency",
        consolidatedaccounts: "Consolidated Accounts",
        costofsales: "Cost of Sales",
        wages: "Wages & Salaries",
        emoluments: "Director Emoluments",
        depreciation: "Depreciation",
        auditfees: "Audit Fees",
        interestpayments: "Interest Payments",
        pretax: "Pre-Tax Profit",
        tax: "Tax",
        pat: "Profit After Tax",
        retainedprofit: "Retained Profit",
        export: "Export",
        cash: "Cash",
        networth: "Net Worth",
        liquidity: "Liquidity Acid Test",
        gearing: "Gearing",
        roce: {
            short: "RoCE",
            full: "Return on Capital Employed %"
        },
        incomestatement: "Income Statement",
        balancesheet: "Balance Sheet",
        ratios: "Ratios",
        profitandloss: "Profit & Loss",
        debtors: {
            trade: "Trade Debtors",
            other: "Other Debtors"
        },
        longtermfinance: {
            total: "Total Long Term Finance",
            other: "Other Long Term Finance"
        },
        shorttermfinance: "Other Short Term Finance",
        assets: {
            assets: "Assets",
            tangible: "Tangible Assets",
            intangible: "Intangible Assets",
            current: "Total Current Assets",
            misc: "Miscellaneous Current Assets",
            fixed: "Total Fixed Assets"
        },
        liabilities: {
            liabilities: "Liabilities",
            current: "Total Current Liabilities",
            misc: "Miscellaneous Current Liabilities"
        },
        creditors: "Trade Creditors",
        pretaxmargin: "Pre-Tax Profit Margin",
        currentratio: "Current Ratio",
        salesnetworkingcapital: "Sales Networking Capital",
        equity: "Equity (%)",
        creditordays: "Creditor Days",
        rotae: "Return on Total Assets Employed (%)",
        currentdebt: "Current Debt Ratio",
        totaldebt: "Total Debt Ratio",
        stockturnover: "Stock Turnover Ratio (%)",
        ronae: "Return on Net Assets Employed (%)"
    },
    people: {
        title: "People",
        upgradeAccountMessage: "Please, upgrade to a paid account to see contacts"
    },
    techstack: {
        title: "Technology Stack",
        datefirstverified: "Date First Verified",
        datelastverified: "Date Last Verified",
        vendor: "Vendor",
        product: "Product",
        category: "Category",
        intensity: "Intensity"
    },
    news: {
        title: "News"
    },
    social: {
        title: "Social",
        twitter: {
            nouser: "X user data is not available for this organisation",
            followon: "Follow On X",
            top5mentions: "Top 5 Mentions",
            top5hashtags: "Top 5 Hashtags"
        },
        youtube: {
            nouser: "YouTube user data is not available for this organisation"
        }
    },
    shareholders: {
        title: "Shareholders"
    },
    channelpartners: {
        title: "Channel Partners"
    },
    edit: {
        title: "Edit Organisation"
    },
    contracts: {
        title: "Contracts"
    },
    casestudies: {
        title: "Case Studies"
    },
    whitepaper: {
        title: "Whitepaper"
    },
    spend: {
        title: "Spend"
    },
    previousnames: {
        title: "Previous Names",
        name: "Name",
        effectivefrom: "Effective From",
        ceased: "Ceased"
    }
}