import React, {useEffect, useState} from "react";
import {Button, Col, Form, ListGroup, ListGroupItem, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faPencilAlt, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import translate from "../../i18n/translate";
import {format} from "d3-format";
import {frontEndHostName, organisationHostname} from "../../utils/Configuration";
import axios from "axios";
import IqLoadingIcon from "../common/IqLoadingIcon";
import Select from "react-select";
import {SelectOption} from "../news/GlobalNews";
import {FilterRange} from "../publicsector/contracts/ContractFilters";
import SweetAlert from "react-bootstrap-sweetalert/dist";

export interface UserListFilters {
    id?: number | null,
    userId?: number | null,
    title?: string,
    filters?: ListFilters
}

export interface ListFilters {
    turnover?: FilterRange,
    employees?: FilterRange,
    companyTypes?: string[],
    categories?: string[],
    vendors?: string[]
}

interface Props {
    applyFilters: (f: UserListFilters) => void
}

const ListFiltersModal: React.FC<Props> = (props: Props) => {
    const turnoverOptions = [0, 100000, 250000, 500000, 1000000, 5000000, 10000000, 100000000, 500000000, 1000000000];
    const employeeOptions = [0, 50, 100, 250, 500, 1000, 5000, 10000];
    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

    const [success, setSuccess] = useState<boolean>(false);
    const [filters, setFilters] = useState<UserListFilters[]>([]);
    const [loadingFilters, setLoadingFilters] = useState<boolean>(false);
    useEffect(() => {
        const loadFilters = async () => {
            setLoadingFilters(true);

            await axios.get(frontEndHostName + "user-list/filters")
                .then(r => setFilters(r.data))
                .finally(() => setLoadingFilters(false));
        };

        loadFilters();
    }, []);

    const [hasAddPermission, setAddPermission] = useState<boolean>(false);
    useEffect(() => {
        const fetchAddPermission = async () => {
            await axios.get(frontEndHostName + "user-list/filters/has-capacity")
                .then(r => setAddPermission(r.data));
        };

        if (showFiltersModal) {
            fetchAddPermission();
        }
    }, [showFiltersModal, filters]);

    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [minTurnover, setMinTurnover] = useState<number>();
    const [maxTurnover, setMaxTurnover] = useState<number>();

    const [minEmployees, setMinEmployees] = useState<number>();
    const [maxEmployees, setMaxEmployees] = useState<number>();

    const [companyTypes, setCompanyTypes] = useState<string[]>([]);
    const [loadingCompanyTypes, setLoadingCompanyTypes] = useState<boolean>(false);
    const [companyTypesError, setCompanyTypesError] = useState<boolean>(false);
    const [companyTypeOptions, setCompanyTypeOptions] = useState<SelectOption[]>([]);
    const updateCompanyTypes = (selected: SelectOption[]) => {
        setCompanyTypes(
            selected?.map((item: SelectOption) => item.value)
        );
    };

    useEffect(() => {
        const fetchCompanyTypes = async () => {
            setLoadingCompanyTypes(true);

            await axios.get(organisationHostname + "primary-business-types")
                .then(r => {
                    let options: SelectOption[] = r.data.map((item: string) => ({
                        value: item,
                        label: item
                    }));

                    setCompanyTypeOptions(options);
                })
                .catch(() => setCompanyTypesError(true))
                .finally(() => setLoadingCompanyTypes(false));
        };

        fetchCompanyTypes();
    }, []);

    const [loadingTechStackFilters, setLoadingTechStackFilters] = useState<boolean>(false);
    const [vendorOptions, setVendorOptions] = useState<SelectOption[]>([]);
    const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([]);

    const [vendors, setVendors] = useState<string[]>();
    const updateVendors = (selected: SelectOption[]) => {
        setVendors(
            selected?.map((item: SelectOption) => item.value)
        );
    };

    const [categories, setCategories] = useState<string[]>();
    const updateCategories = (selected: SelectOption[]) => {
        setCategories(
            selected?.map((item: SelectOption) => item.value)
        );
    };

    useEffect(() => {
        const fetchTechStackFilters = async () => {
            setLoadingTechStackFilters(true);

            await axios.get(organisationHostname + "organisation/tech-stack-filter-options")
                .then((response) => {
                    let data = response.data;

                    setCategoryOptions(
                        data.categories.map((c: string) => {
                            return {
                                label: c,
                                value: c
                            }
                        })
                    );

                    setVendorOptions(
                        data.vendors.map((v: string) => {
                            return {
                                label: v,
                                value: v
                            }
                        })
                    );
                    setLoadingTechStackFilters(false);
                })
                .catch(error => console.log(error.message))
                .finally(() => setLoadingTechStackFilters(false));
        };

        fetchTechStackFilters();
    }, []);

    const buildFilters = () => {
        let listFilters: ListFilters = {
            turnover: {
                from: minTurnover && minTurnover >= 0 ? minTurnover : null,
                to: maxTurnover && maxTurnover >= 0 ? maxTurnover : null
            },
            employees : {
                from: minEmployees && minEmployees >= 0 ? minEmployees : null,
                to: maxEmployees && maxEmployees >= 0 ? maxEmployees : null
            },
            companyTypes: companyTypes,
            categories: categories,
            vendors: vendors
        };

        return listFilters;
    };

    const hideEditor = () => {
        clearFilters();
        setShowEditor(false);
    };

    const clearFilters = () => {
        setMinTurnover(undefined);
        setMaxTurnover(undefined);
        setMinEmployees(undefined);
        setMaxEmployees(undefined);
        setCompanyTypes([]);
        setVendors([]);
        setCategories([]);
    };

    const applyFilters = (id: number) => {
        let f = filters.find((f: UserListFilters) => f.id === id);
        if (f) {
            props.applyFilters(f);
            hideEditor();
            setShowFiltersModal(false);
        }
    };

    const [deleting, setDeleting] = useState<boolean>(false);
    const deleteFilters = async (id: number) => {
        setDeleting(true);
        await axios.delete(frontEndHostName + "user-list/filters/" + id + "/delete")
            .then(() => {
                let filteredList = filters.filter((f: UserListFilters) => f.id !== id)
                setFilters(filteredList);

                setSuccess(true);
            })
            .finally(() => setDeleting(false));
    };

    const [selected, setSelected] = useState<UserListFilters | null>(null);
    const createNew = () => {
        let f: UserListFilters = {
            id: null,
            title: ""
        };

        setSelected(f);
        clearFilters();
        setShowEditor(true);
    };

    const openEditor = (id: number) => {
        let f = filters.find((f: UserListFilters) => f.id === id);
        if (f) {
            setSelected(f);

            let currentFilters = f.filters!;
            setMinTurnover(currentFilters.turnover?.from);
            setMaxTurnover(currentFilters.turnover?.to);
            setMinEmployees(currentFilters.employees?.from);
            setMaxEmployees(currentFilters.employees?.to);
            setCompanyTypes(currentFilters.companyTypes ? currentFilters.companyTypes : []);
            setVendors(currentFilters.vendors);
            setCategories(currentFilters.categories);
        }

        setShowEditor(true);
    };

    const setTitle = (title: string) => {
        let updated = {
            ...selected,
            title: title
        } as UserListFilters;

        setSelected(updated);
    };

    const saveFilters = async () => {
        let current = buildFilters();
        let postData = {
            ...selected,
            filters: current
        };

        await axios.post(frontEndHostName + "user-list/filters", postData)
            .then(r => {
                let item = filters.find((item: UserListFilters) => item.id === postData.id);
                if (item) {
                    let index = filters.indexOf(item);
                    filters.splice(index, 1, r.data);

                    setFilters(filters);
                }
                else {
                    setFilters([
                        ...filters,
                        r.data
                    ]);
                }

                hideEditor();
                setSuccess(true);
            });
    };

    return (
        <span>
            <Button className="me-3"
                    onClick={() => setShowFiltersModal(true)}
                    size="sm">
                <span className="me-2">Filters</span>
                <span>
                    <FontAwesomeIcon icon={faFilter}/>
                </span>
            </Button>
            <Modal show={showFiltersModal}
                   size="lg"
                   onHide={() => setShowFiltersModal(false)}>
                <Modal.Header closeButton>
                    Filters
                </Modal.Header>
                <Modal.Body>
                    {loadingFilters ? <IqLoadingIcon/> : (
                        <div>
                            {showEditor ? (
                                <div>
                                    <Row className="mb-2">
                                        <Col className="pt-2" xs={4} sm={4} md={3} lg={3} xl={3}>
                                            <span className="organisation-card-label">Name</span>
                                        </Col>
                                        <Col>
                                            <input type="text"
                                                   className="form-control"
                                                   value={selected!.title}
                                                   placeholder="Filter Name..."
                                                   onChange={(event: any) => setTitle(event.target.value)}/>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col className="pt-2" xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <span className="organisation-card-label">{translate("lists.turnover")}</span>
                                        </Col>
                                        <Col xs={3} sm={3} md={4} lg={4} xl={4}>
                                            <Form.Select className="form-control" value={minTurnover}
                                                    onChange={(e: any) => setMinTurnover(e.target.value)}>
                                                <option>{translate("contracts.nomin")}</option>
                                                {
                                                    turnoverOptions
                                                        .filter((option: number) => !maxTurnover || option < maxTurnover)
                                                        .map((opt: number) => (
                                                            <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                                        ))
                                                }
                                            </Form.Select>
                                        </Col>
                                        <Col className="text-center pt-2" xs={2} sm={2} md={1} lg={1} xl={1}>
                                            <span>{translate("advancedsearch.to")}</span>
                                        </Col>
                                        <Col xs={3} sm={3} md={4} lg={4} xl={4}>
                                            <Form.Select className="form-control" value={maxTurnover}
                                                    onChange={(e: any) => setMaxTurnover(e.target.value)}>
                                                <option>{translate("contracts.nomax")}</option>
                                                {
                                                    turnoverOptions
                                                        .filter((option: number) => !minTurnover || option > minTurnover)
                                                        .map((opt: number) => (
                                                            <option
                                                                value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                                        ))
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col className="pt-2" xs={4} sm={4} md={3} lg={3} xl={3}>
                                            <span className="organisation-card-label">{translate("lists.employees")}</span>
                                        </Col>
                                        <Col xs={3} sm={3} md={4} lg={4} xl={4}>
                                            <Form.Select className="form-control" value={minEmployees}
                                                    onChange={(e: any) => setMinEmployees(e.target.value)}>
                                                <option>{translate("contracts.nomin")}</option>
                                                {
                                                    employeeOptions
                                                        .filter((option: number) => !maxEmployees || option < maxEmployees)
                                                        .map((opt: number) => (
                                                            <option value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                                        ))
                                                }
                                            </Form.Select>
                                        </Col>
                                        <Col className="text-center pt-2" xs={2} sm={2} md={1} lg={1} xl={1}>
                                            <span>{translate("advancedsearch.to")}</span>
                                        </Col>
                                        <Col xs={3} sm={3} md={4} lg={4} xl={4}>
                                            <Form.Select className="form-control" value={maxEmployees}
                                                    onChange={(e: any) => setMaxEmployees(e.target.value)}>
                                                <option>{translate("contracts.nomax")}</option>
                                                {
                                                    employeeOptions
                                                        .filter((option: number) => !minEmployees || option > minEmployees)
                                                        .map((opt: number) => (
                                                            <option
                                                                value={opt}>{format(',.3~s')(opt).replace(/G/, "B")}</option>
                                                        ))
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col className="pt-2" xs={4} sm={4} md={3} lg={3} xl={3}>
                                            <span className="organisation-card-label">{translate("lists.companytype")}</span>
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Select isMulti
                                                    isDisabled={loadingCompanyTypes || companyTypesError}
                                                    options={companyTypeOptions}
                                                    value={
                                                        companyTypes?.map((id: string) => companyTypeOptions.find((option: SelectOption) => option.value === id))
                                                    }
                                                    onChange={(e: any) => updateCompanyTypes(e)}
                                                    placeholder={translate("lists.companytype")}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"/>
                                        </Col>
                                    </Row>

                                    <hr />
                                    <Row className="mb-2">
                                        <Col>
                                            <span className="organisation-card-label">{translate("organisation.techstack.title")}</span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col className="pt-2" xs={4} sm={4} md={3} lg={3} xl={3}>
                                            <span className="organisation-card-label">{translate("organisation.techstack.vendor")}</span>
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Select isMulti
                                                    isDisabled={loadingTechStackFilters}
                                                    options={vendorOptions}
                                                    value={
                                                        vendors?.map((id: string) => vendorOptions.find((option: SelectOption) => option.value === id))
                                                    }
                                                    onChange={(e: any) => updateVendors(e)}
                                                    placeholder={translate("organisation.techstack.vendor")}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"/>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col className="pt-2" xs={4} sm={4} md={3} lg={3} xl={3}>
                                            <span className="organisation-card-label">{translate("organisation.techstack.category")}</span>
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Select isMulti
                                                    isDisabled={loadingTechStackFilters}
                                                    options={categoryOptions}
                                                    value={
                                                        categories?.map((id: string) => categoryOptions.find((option: SelectOption) => option.value === id))
                                                    }
                                                    onChange={(e: any) => updateCategories(e)}
                                                    placeholder={translate("organisation.techstack.category")}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"/>
                                        </Col>
                                    </Row>

                                    <Row className="mt-2 pe-3 pull-right">
                                        <button className="iqx-button sm-size iconic me-2"
                                                onClick={() => {setSelected(null); hideEditor()}}>
                                            <span>Cancel</span>
                                        </button>
                                        <button className="iqx-button sm-size primary"
                                                disabled={!selected || selected.title?.length === 0}
                                                onClick={() => saveFilters()}>
                                            <span>Save</span>
                                        </button>
                                    </Row>
                                </div>
                            ) : (
                                <div>
                                    <div className="row mb-2">
                                        <div className="col">
                                            <button className="iqx-button primary sm-size pull-right"
                                                    disabled={!hasAddPermission}
                                                    onClick={() => createNew()}>
                                                <span className="me-2">Create New</span>
                                                <FontAwesomeIcon icon={faPlus}/>
                                            </button>
                                        </div>
                                    </div>
                                    <ListGroup>
                                        {filters.map((item: UserListFilters) => (
                                            <ListGroupItem>
                                                <Row>
                                                    <Col className="pt-1">
                                                        {item.title}
                                                    </Col>
                                                    <Col xl={3} className="text-end">
                                                    <span className="me-2">
                                                        <Button variant="outline-success"
                                                                disabled={deleting}
                                                                size="sm"
                                                                title="Apply"
                                                                onClick={() => applyFilters(item.id!)}>
                                                            <FontAwesomeIcon icon={faFilter}/>
                                                        </Button>
                                                    </span>
                                                        <span className="me-2">
                                                        <Button variant="outline-secondary"
                                                                disabled={deleting}
                                                                size="sm"
                                                                title="Edit"
                                                                onClick={() => openEditor(item.id!)}>
                                                            <FontAwesomeIcon icon={faPencilAlt}/>
                                                        </Button>
                                                    </span>
                                                        <span className="me-2">
                                                        <Button variant="danger"
                                                                disabled={deleting}
                                                                size="sm"
                                                                title="Delete"
                                                                onClick={() => deleteFilters(item.id!)}>
                                                            <FontAwesomeIcon icon={faTrashAlt}/>
                                                        </Button>
                                                    </span>
                                                    </Col>
                                                </Row>
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>
            </Modal>


            <SweetAlert success
                        show={success}
                        title="Success!"
                        onConfirm={() => setSuccess(false)}
                        onCancel={() => setSuccess(false)}>
            </SweetAlert>
        </span>
    );
};

export default ListFiltersModal;