import React, { useContext, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { socialHostName } from "../../../utils/Configuration";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import { hasAnyRole, hasUserRole, Role } from "../../../utils/Security";
import RequestContactsModal from "../../common/request-contacts/RequestContactsModal";
import translate from "../../../i18n/translate";
import { JobRequest } from "../../../model/organisation/JobRequest";
import {Form, Select, Slider} from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import IqSmallLoadingIcon from "../../common/IqSmallLoadingIcon";


interface Props {
    refresh: boolean;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const OrganisationJobsModal: React.FC<Props> = (props) => {

    const { refresh } = props

    const [form] = Form.useForm();
    const { organisation } = useContext(UserOrganisationContext);

    const [showOrganisationJobsModal, setShowOrganisationJobsModal] = useState<boolean>(false);

    const [error, setError] = useState<boolean>(false);
    const [warning, setWarning] = useState<boolean>(false);

    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [jobTitle, setJobTitle] = useState<string>("")
    const [jobLocation, setJobLocation] = useState<string>("")
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)



    const handleClose = () => {
        setShowOrganisationJobsModal(false);
    };


    const handleRequest = (payload: JobRequest) => {
        setLoading(true);

        axios.post(socialHostName + "jobs/company-search", payload)
            .then(() => {
                setShowSuccess(true);
            })
            .catch((error) => {
                if (error.response?.data?.errorKey === "consumptionexceeded") {
                    setWarning(true);
                } else {
                    setError(true);
                }
            })
            .finally(() => setLoading(false));
    };

    const isOrganisationGotWebsite = () => {
        if (!organisation.website || organisation.website.trim() === ""){
            return true
        }
        return false
    }

    return (
        <>
            <Row>
                {hasUserRole(Role.INTERNAL) && (
                    <Col>
                        <Button variant="primary"
                            onClick={() => setShowOrganisationJobsModal(true)} disabled={isOrganisationGotWebsite()}>
                            {translate("organisation.jobs.requestJobs")}
                        </Button>
                    </Col>
                )}
            </Row>
            <Modal show={showOrganisationJobsModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Request Jobs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        form={form}
                        layout="vertical"
                        className="request-contacts-form">
                        <Form.Item label="Job Title">
                            <input
                                disabled={false}
                                autoFocus={true}
                                type="text"
                                className="input-modal-box"
                                placeholder={"Input Job Title"}
                                value={jobTitle}
                                onChange={e =>  setJobTitle(e.target.value)}>
                            </input>
                        </Form.Item>
                        {loading ? <span style={{ color: "white" }}><IqSmallLoadingIcon /></span> :
                        <Button variant="primary" onClick={() => handleRequest({
                            companyName: organisation.companyName,
                            countryCode: organisation.countryCode,
                            jobTitle: jobTitle,
                            organisationId: organisation.id
                        })}>
                            Request
                        </Button>
                        }
                    </Form>
                </Modal.Body>

                <SweetAlert danger
                            show={error}
                            title="An error has occurred!"
                            onConfirm={() => {setError(false); setShowOrganisationJobsModal(false);}}
                            onCancel={() => setError(false)}>
                    Please try again later.
                </SweetAlert>

                <SweetAlert success
                            show={showSuccess}
                            title="Success!"
                            onConfirm={() => { setShowSuccess(false);
                                setShowOrganisationJobsModal(false)
                                props.setRefresh(true)
                            ;}}>
                    Jobs successfully requested.
                </SweetAlert>
            </Modal>
        </>
    );
};

export default OrganisationJobsModal;
