import React, {useEffect, useState} from "react";
import {organisationHostname} from "../../utils/Configuration";
import AppointmentDetails from "../../model/organisation/AppointmentDetails";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNewspaper, faSort, faSortAmountDown, faSortAmountDownAlt} from "@fortawesome/free-solid-svg-icons";
import translate from "../../i18n/translate";
import {Col, Form, Row, Table} from "react-bootstrap";
import DateFormatter from "../../utils/formatters/DateFormatter";
import IqLoadingIcon from "../common/IqLoadingIcon";
import Pagination from "../../utils/PaginationHook";
import {useHistory} from "react-router-dom";
import {formatPosition, titleCase} from "../../utils/formatters/TextFormatter";
import ArticleLink from "./ArticleLink";
import {CompanyLogo} from "./MarketIntelligenceContainer";

enum Sort {
    NAME = "name",
    POSITION = "position",
    ORGANISATION = "organisation",
    DATE= "date",
}

const Appointments: React.FC = () => {
    let history = useHistory();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const changePageSize = (event: any) => {
        let size = Number(event.target.value);
        if (size !== pageSize) {
            setPageSize(size);
        }
    };

    const [sort, setSort] = useState<Sort>(Sort.DATE);
    const [desc, setDesc] = useState<boolean>(true);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [apptDetails, setApptDetails] = useState<AppointmentDetails[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let page = currentPage - 1;
            let url = `${organisationHostname}appointment-details-merged?page=${page}&size=${pageSize}&sort=${sort + "," + (desc ? "DESC" : "ASC")}`;

            await axios.get(url)
                .then(r => {
                    let data = r.data;
                    setApptDetails(data.content);
                    setTotalRecords(data.totalElements);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };

        fetchData();
    }, [currentPage, pageSize, sort, desc]);

    const getSortButton = (s: Sort) => {
        if (s === sort) {
            return (
                <a className="sort-button pull-right"
                   onClick={() => {
                       setDesc(!desc);
                   }}>
                    <FontAwesomeIcon icon={desc ? faSortAmountDown : faSortAmountDownAlt}/>
                </a>
            );
        }

        return (
            <a className="sort-button pull-right"
               onClick={() => {
                   setSort(s);
                   setDesc(true);
               }}>
                <FontAwesomeIcon icon={faSort}/>
            </a>
        );
    };



    return (
        <div style={{paddingTop: 35}}>
            <div className="organisation-card">
                <div className="organisation-card-label">
                    {translate("marketintelligence.appointments.title")}
                </div>
                <div className="mt-4">
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th style={{ width: "30%" }}>
                                    {translate("contracts.organisation")}
                                    {getSortButton(Sort.ORGANISATION)}
                                </th>
                                <th>
                                    {translate("marketintelligence.appointments.name")}
                                    {getSortButton(Sort.NAME)}
                                </th>
                                <th>
                                    {translate("marketintelligence.appointments.position")}
                                    {getSortButton(Sort.POSITION)}
                                </th>
                                <th>
                                    {translate("organisation.summary.funding.date")}
                                    {getSortButton(Sort.DATE)}
                                </th>
                                <th className="text-end">
                                    <span>
                                        <FontAwesomeIcon icon={faNewspaper} size="1x"/>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        {!loading && (
                            <tbody>
                                {apptDetails.map((appointment: AppointmentDetails) => (
                                    <tr key={appointment.id + (Math.random() + 1).toString(36).substring(5)}>
                                        <td>
                                            <span className="pe-3">
                                                <CompanyLogo {...appointment.organisation} />
                                            </span>
                                            <span className="iqx-link pt-2" onClick={() => history.push("/organisation/" + appointment.organisation.id)}>
                                                {appointment.organisation.companyName.toUpperCase()}
                                            </span>
                                        </td>
                                        <td>
                                            <span className="pt-2">
                                                {titleCase(appointment.name)}
                                            </span>
                                        </td>
                                        <td>
                                            {formatPosition(appointment.position)}
                                        </td>
                                        <td>
                                            {DateFormatter.formatDate(appointment.date)}
                                        </td>
                                        <td className="text-end">
                                            <ArticleLink articleId={appointment.articleUuid}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </Table>

                    {loading && (
                        <div className="row text-center">
                            <IqLoadingIcon />
                        </div>
                    )}

                    <Row className="justify-content-md-end">
                        <Col md={1}>
                            <Form.Control as="select"
                                          className="pull-right"
                                          defaultValue={pageSize}
                                          onChange={(event) => changePageSize(event)}
                                          >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                            </Form.Control>
                        </Col>
                        <Col md={2}>
                            <div className="pull-right">
                                {totalRecords > 0 && (
                                    <Pagination totalRecords={totalRecords}
                                                pageLimit={pageSize}
                                                pageRangeDisplayed={1}
                                                initPage={currentPage}
                                                onChangePage={setCurrentPage}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default Appointments;