import React, {useContext, useEffect, useState} from 'react';
import './MainNavbar.css';
import {useHistory} from "react-router-dom";
import {Button, Form, FormControl, InputGroup, Nav, Navbar, OverlayTrigger, Popover} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faBell, faCog, faSignOutAlt, faUser} from '@fortawesome/free-solid-svg-icons';
import {hasUserRole, Role} from '../../utils/Security'
import LoginContext from '../../context/LoginContext'
import LanguageToggle from "./LanguageToggle";
import {Select} from 'antd';
import SearchContext from '../../context/SearchContext';
import SearchHistory from "./SearchHistory";
import translate from "../../i18n/translate";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import IqLoadingIcon from "./IqLoadingIcon";
import Notifications from '../notifications/Notifications';
import NavBarActionButton from './NavBarActionButton';
import NotificationContext from '../notifications/NotificationContext';
import styled from 'styled-components';

const Option = Select.Option;

interface Props {
    sideBarCollapsed: boolean,
    setSideBarCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

enum FilterOptions {
    ACTIVE = "Active",
    UK = "UK",
    US = "US",
    DE = "DE",
    FR = "FR",
    RO = "RO"
}

const CountBubble = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 50%;
    flex-shrink: 0;
    bottom: 0;
    right: 0;
    margin-right: -5px;
    margin-bottom: -5px;
    font-size: 0.6em;
  `;

const MainNavbar: React.FC<Props> = (props: Props) => {

    const { logged, setLogged } = useContext(LoginContext);
    const { searchQuery, setSearchQuery } = useContext(SearchContext);
    const history = useHistory();
    const [previousRoute, setPreviousRoute] = useState("/dashboard");
    const [showNotifications, setShowNotifications] = useState<boolean>(false);

    const [showFilters, setShowFilters] = useState(false);

    let filterOptions = [FilterOptions.ACTIVE];
    if (hasUserRole(Role.ADMIN)) {
        filterOptions.push(FilterOptions.UK, FilterOptions.DE, FilterOptions.FR, FilterOptions.RO, FilterOptions.US);
    }
    else {
        if (hasUserRole(Role.GEO_UK)) {
            filterOptions.push(FilterOptions.UK);
        }

        if (hasUserRole(Role.GEO_FR)) {
            filterOptions.push(FilterOptions.FR);
        }

        if (hasUserRole(Role.GEO_DE)) {
            filterOptions.push(FilterOptions.DE);
        }
        
        if (hasUserRole(Role.GEO_RO)) {
            filterOptions.push(FilterOptions.RO);
        }
        if (hasUserRole(Role.GEO_US)) {
            filterOptions.push(FilterOptions.US);
        }
    }
    const [selectedFilters, setSelectedFilters] = useState(filterOptions);

    const doSearch = (searchValue: string) => {
        if (!window.location.pathname.includes("/search")) {
            setPreviousRoute(window.location.pathname);
        }
        if (searchValue.length >= 1) {
            let escaped = encodeURIComponent(searchValue);
            setSearchQuery(searchValue);
            history.push("/search?q=" + escaped + "&filters=" + selectedFilters);
        } else {
            history.push(previousRoute);
        }
    };
    useEffect(() => {
        if (searchQuery.length >= 1 && window.location.pathname.includes("/search")) {
            doSearch(searchQuery);
        }
    }, [selectedFilters]);

    const handleSearchQueryChange = (e: any) => {
        e.persist();
        setSearchQuery(e.target.value);
        doSearch(e.target.value);
    };

    const submit = (e: any) => {
        e.preventDefault();
        doSearch(searchQuery);
    };

    const [loggingOut, setLoggingOut] = useState<boolean>(false);
    const logOut = async (e: React.MouseEvent) => {
        setLoggingOut(true);
        await axios.post('/api/logout')
            .then(() => {
                localStorage.removeItem("login");
                setLogged(false);
            })
            .finally(() => setLoggingOut(true));
    };

    const collapse = () => {
        props.setSideBarCollapsed(!props.sideBarCollapsed);
    };


    const NavBarCollapseButton = () => {
        return (
            <Button type="button"
                style={{ backgroundColor: "white", width: 40, height: 35, borderRadius: 5, borderColor: "#3378D1" }}
                onClick={() => {
                    collapse()
                }}>
                <FontAwesomeIcon icon={faBars} color="#3378D1" />
            </Button>
        )
    };


    const [counter, setCounter] = useState<number>(0);


    return (
        <div>
            <Navbar bg="#E2EAF1" expand="sm">
                <Navbar.Brand className="ms-1">
                    {logged
                        ?
                        <NavBarCollapseButton />
                        :
                        <div style={{ width: 40, height: 35 }} />
                    }
                </Navbar.Brand>
                {logged &&
                    <>
                        <Form className="d-flex" style={{ width: "100%" }} onSubmit={submit}>
                            <InputGroup style={{ width: "100%" }}>
                                <div id="navbar-search">
                                    <FormControl type="text" placeholder={translate("advancedsearch.search")}
                                        className="me-sm-2" style={{ width: "100%" }} value={searchQuery}
                                        onChange={handleSearchQueryChange}>
                                    </FormControl>
                                    <div id="navbar-search-history">
                                        <OverlayTrigger trigger="click"
                                            placement="bottom"
                                            rootClose={true}
                                            overlay={
                                                <Popover style={{ minWidth: "1000px" }} id="quicksearch-history">
                                                    <SearchHistory />
                                                </Popover>
                                            }>
                                            <Button variant={"outline-primary"} className="btn-sm nav-filters-button">
                                                {translate("nav.recentsearches")}
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                    <div id="navbar-search-filter">
                                        {!showFilters
                                            ?
                                            <Button variant={"outline-primary"} className="btn-sm nav-filters-button"
                                                onClick={() => {
                                                    setShowFilters(true)
                                                }}>
                                                {translate("nav.filters")}
                                            </Button>
                                            :
                                            <Select
                                                mode="multiple"
                                                size={"small"}
                                                style={{ width: '100%', minWidth: 120 }}
                                                placeholder="Choose filters"
                                                value={selectedFilters}
                                                onChange={(val: FilterOptions[]) => {
                                                    setSelectedFilters(val)
                                                }}
                                                autoFocus={true}
                                                onBlur={() => {
                                                    setShowFilters(false)
                                                }}
                                            >
                                                {filterOptions.map(o => {
                                                    return (<Option key={o} value={o}>{o}</Option>);
                                                })}
                                            </Select>
                                        }
                                    </div>
                                </div>
                            </InputGroup>

                        </Form>
                        <Nav className="me-auto" />
                        <Nav id="nav-bar-action-buttons">
                            {hasUserRole(Role.ADMIN) && <NavBarActionButton to="/admin/users" type={faCog} onClick={() => {
                            }} />}
                            <NotificationContext.Provider value={{ counter, setCounter }}>
                                <NavBarActionButton to={"#"} type={faBell} onClick={(e: Event) => {
                                    setShowNotifications(!showNotifications);
                                    e.preventDefault();
                                }}>
                                    {(counter > 0 && <CountBubble>{counter}</CountBubble>) || <></>}
                                </NavBarActionButton>
                                <Notifications show={showNotifications} setShow={setShowNotifications} />
                            </NotificationContext.Provider>
                            <NavBarActionButton to="/profile/settings" type={faUser} onClick={() => {
                            }} />

                            <NavBarActionButton to="#" type={faSignOutAlt} onClick={(e: any) => {
                                logOut(e)
                            }} />
                            <LanguageToggle />
                        </Nav>
                    </>
                }

            </Navbar>
            <SweetAlert controlled
                showCloseButton={false}
                showConfirm={false}
                show={loggingOut}
                title={translate("signingout")}
                onConfirm={() => { }}
                onCancel={() => { }}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "1em",
                    backgroundColor: "white", borderRadius: "0.45rem", padding: 15, width: "100%"
                }}>
                    <IqLoadingIcon />
                </div>
            </SweetAlert>
        </div>
    );
}

export default MainNavbar;
