import React, {useContext, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import axios from "axios";
import {organisationHostname} from "../../../utils/Configuration";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import {hasAnyRole, Role} from "../../../utils/Security";
import RequestContactsModal from "../../common/request-contacts/RequestContactsModal";
import translate from "../../../i18n/translate";
import {ContactRequest} from "../../../model/contacts/ContactRequest";


const OrganisationRequestContactsModal: React.FC = () => {
    const { organisation } = useContext(UserOrganisationContext);

    const [showRequestContactsModal, setShowRequestContactsModal] = useState<boolean>(false);

    const [error, setError] = useState<boolean>(false);
    const [warning, setWarning] = useState<boolean>(false);

    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleClose = () => {
        setShowRequestContactsModal(false);
    };

    const [jobTitleOptions, setJobTitleOptions] = useState<string[]>([]);
    useEffect(() => {
        const fetchJobTitleOptions = async () => {
            await axios.get(organisationHostname + "contacts/jobtitles")
                .then(r => setJobTitleOptions(r.data));
        }

        fetchJobTitleOptions();
    }, []);

    const handleRequest = (payload: ContactRequest) => {
        setLoading(true);
        axios.post(organisationHostname + "organisation/" + organisation.id + "/update-cognism-contacts", payload)
            .then(() => {
                setShowSuccess(true);
            })
            .catch((error) => {
                if (error.response?.data?.errorKey === "consumptionexceeded") {
                    setWarning(true);
                } else {
                    setError(true);
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Row>
                {hasAnyRole([Role.ADMIN, Role.DISCOVERY_ONE, Role.DISCOVERY_TWO, Role.DISCOVERY_THREE]) && (
                    <Col>
                        <button className="iqx-button primary md-size me-2"
                                onClick={() => setShowRequestContactsModal(true)}>
                            {translate("lists.requestcontacts")}
                        </button>
                    </Col>
                )}
            </Row>
            <RequestContactsModal showRequestContactsModal={showRequestContactsModal}
                                  setShowRequestContactsModal={setShowRequestContactsModal}
                                  handleClose={handleClose}
                                  handleRequest={handleRequest}
                                  error={error}
                                  setError={setError}
                                  warning={warning}
                                  setWarning={setWarning}
                                  showSuccess={showSuccess}
                                  setShowSuccess={setShowSuccess}
                                  loading={loading}
                                  jobTitles={jobTitleOptions}/>
        </>
    );
};

export default OrganisationRequestContactsModal;
