import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {socialHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHubspot} from "@fortawesome/free-brands-svg-icons";
import {Row} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert/dist";

const HubSpotLanding: React.FC = () => {
    let history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    useEffect(() => {
        registerAuthCode(query.get("code"));
    }, []);

    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const registerAuthCode = (authCode: string | null) => {
        setLoading(true);
        axios.post(socialHostName + 'hubspot/register-auth-code', authCode, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => setSuccess(true))
            .catch(error => setError(true))
            .finally(() => {
                setLoading(false);
            });
    };

    const accept = () => {
        history.push("/profile");
    };

    return (
        <Row className="mt-3">
            {loading && <IqLoadingIcon />}
            <SweetAlert success
                        show={success}
                        title="Success!"
                        onConfirm={() => accept()}
                        onCancel={() => accept()}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "white", borderRadius: "0.45rem", padding: 15, margin: 10, width: "100%"
                }}>
                    <span><FontAwesomeIcon icon={faHubspot} color="#0073B1" size="6x" /></span>
                    <span style={{
                                fontWeight: 500,
                                fontSize: 18,
                                marginTop: 15
                            }}>
                        Your account has been linked successfully with IQBlade.
                    </span>
                </div>
            </SweetAlert>
            <SweetAlert danger
                        show={error}
                        title="An error has occurred!"
                        onConfirm={() => accept()}
                        onCancel={() => accept()}>
                Please try again later.
            </SweetAlert>
        </Row>
    );
};

export default HubSpotLanding;