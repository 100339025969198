import React, {useState} from "react";
import {UserResponse} from "../../model/users/UserResponse";
import {Col, Row} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import ProfileContainer from "./ProfileContainer";
import ChangePasswordForm from "./ChangePasswordForm";
import CurrentAllowance from "./CurrentAllowance";
import ChangeAvatarForm from "./ChangeAvatarForm";
import OnboardingPreferencesForm from "../onboarding/OnboardingPreferencesForm";
import ChangeInterests from "./ChangeInterests";

const ProfileSettings: React.FC = () => {

    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [user, setUser] = useState<UserResponse>({} as UserResponse);

    return (
        <div>
            {}
            <ProfileContainer/>
            <Row className="mt-3">
                <Col xs={12} sm={6} lg={6}>
                    <ChangePasswordForm/>
                </Col>
                <Col xs={6} sm={3} lg={3}>
                    <CurrentAllowance/>
                </Col>
                <Col xs={6} sm={3} lg={3}>
                    <ChangeInterests/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs={12} sm={6} lg={6}>
                    <ChangeAvatarForm setUserCallback={setUser} setSuccessCallback={setSuccess} setErrorCallback={setError}/>
                </Col>
                <Col xs={12} sm={6} lg={6}>
                    <OnboardingPreferencesForm/>
                </Col>
            </Row>

            <SweetAlert danger
                        show={error}
                        title="An error has occurred!"
                        onConfirm={() => setError(false)}
                        onCancel={() => setError(false)}>
                Please try again later.
            </SweetAlert>

            <SweetAlert success
                        show={success}
                        title="Success!"
                        onConfirm={() => setSuccess(false)}
                        onCancel={() => setSuccess(false)}>
                Saved successfully!
            </SweetAlert>
        </div>
    );
}


export default ProfileSettings;