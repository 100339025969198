import React, {useEffect, useState} from "react";
import axios from "axios";
import {Col, Container, Modal, Row, Table} from "react-bootstrap";
import IqLoadingIcon from "../common/IqLoadingIcon";
import AsyncSelect from "react-select/async";
import {csvUploadHostName, searchHostname} from "../../utils/Configuration";
import {SelectOption} from "../news/GlobalNews";
import SearchCompany from "../../model/advanced-search/SearchCompany";
import {
    faCheckCircle,
    faChevronCircleLeft,
    faCloudUploadAlt,
    faInfoCircle,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AddToListButton from "../organisation/common/AddToListButton";
import {useHistory} from "react-router-dom";
import translate from "../../i18n/translate";
import CSVUploadInfo from "./CSVUploadInfo";
import SweetAlert from "react-bootstrap-sweetalert";
import {useFilePicker} from "use-file-picker";
import {UseFilePickerError} from "use-file-picker/types";

interface CSVRow {
    companyName: string,
    companyNumber: string,
    url: string,
    postCode: string,
    country: string
}

interface CompanyMatchResult {
    companyId: string,
    companyName: string,
    companyNumber: string,
    country: string
}

interface CSVUploadResult {
    row: CSVRow,
    result: CompanyMatchResult
}

interface Props {
    hide: () => void
}

const CSVUpload: React.FC<Props> = (props: Props) => {

    let history = useHistory();
    const [file, setFile] = useState<any>();

    const [uploading, setUploading] = useState<boolean>(false);
    const [results, setResults] = useState<CSVUploadResult[]>();
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [timedOut, setTimedOut] = useState<boolean>(false);

    const { openFilePicker, errors, plainFiles } = useFilePicker({
        multiple: false,
        accept: ['.csv']
    });

    useEffect(() => {
        if (plainFiles && plainFiles.length > 0) {
            let file = plainFiles[0];
            if (!file) {
                errors.push({name: "Error uploading file", plainFile: file} as UseFilePickerError);
            }

            setFile(file);
        }
    }, [plainFiles]);

    const upload = () => {
        setUploading(true);
        let formData = new FormData();
        formData.append("file", file);
        axios.post(csvUploadHostName + "/csv/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Access-Control-Allow-Origin": "*"
                },
                timeout: 60000
            })
            .then(r => {
                setResults(r.data);

                let orgIds =
                    r.data.map((result: CSVUploadResult) => result.result)
                        .filter((match: CompanyMatchResult) => match?.companyId)
                        .map((m: CompanyMatchResult) => m.companyId);

                setOrganisations(orgIds);
            })
            .catch(e => {
                if (e.code === 'ECONNABORTED') {
                    setTimedOut(true);
                }
                setErrorMessage(e.response.data);
                setError(true);
            })
            .finally(() => setUploading(false));
    };

    const [organisations, setOrganisations] = useState<string[]>([]);
    const updateOrganisation = (value: string, index?: number) => {
        if (index !== undefined) {
            let orgs = [...organisations];
            orgs.splice(index, 1, value);
            setOrganisations([...orgs]);
        }
        else {
            setOrganisations([
                ...organisations,
                value
            ]);
        }
    };

    const loadOrganisations = (q: string) => {
        let data = {
            searchQuery: q,
            filters: ["Active"]
        };

        return new Promise<SelectOption[]>((resolve: any, reject: any) => {
            axios.post(searchHostname + "quick-search", data)
                .then((r) => {
                    resolve(
                        r.data.map((o: SearchCompany) => {
                            return {
                                label: o.companyName,
                                value: o.companyId
                            }
                        })
                    );
                })
                .catch(reject);
        });
    };

    const [showInfo, setShowInfo] = useState<boolean>(false);

    const getErrorMessage = () => {
        if (timedOut) {
            return "Request timed out. Consider uploading a smaller file and trying again";
        }

        if (errorMessage) {
            return errorMessage;
        }

        return translate("errors.generic");
    }

    return (
        <Container fluid style={{ marginTop: 15 }}>
            <Row className="" style={{ marginLeft: 0, marginRight: 0 }}>
                <Col>
                    <div className="iq-headline">
                        <span className="text">
                            Upload CSV
                        </span>
                    </div>
                </Col>

                <Col className="text-end">
                    <span style={{ marginLeft: "auto" }}>
                        <button onClick={() => { history.push("/lists") }} className="iqx-button md-size dark-blue">
                            <FontAwesomeIcon icon={faChevronCircleLeft} color="white" size="1x" />
                            <span style={{ paddingLeft: 10 }}>Back to all lists</span>
                        </button>
                    </span>
                </Col>
            </Row>
            <div style={{
                paddingTop: "10px",
                backgroundColor: "white",
                borderRadius: "0.45rem",
                padding: 15,
                marginTop: 35
            }}>
                <div className="row p-3">
                    <div className="col-8 pt-2">
                        <button className="iqx-button primary sm-size pe-4" onClick={() => openFilePicker()}>Select file...</button>
                        {plainFiles.map((file) => (
                            <span className="ms-2" key={file.name}>
                                {file.name}
                            </span>
                        ))}
                    </div>
                    <div className="col-3">
                        <button className="iqx-button md-size dark-blue pull-right" onClick={upload} disabled={!file}>
                            <span style={{ paddingRight: 10 }}>Upload</span>
                            <FontAwesomeIcon icon={faCloudUploadAlt} color="white" size="1x" />
                        </button>
                    </div>
                    <div className="col-1 pt-2">
                        <span className="iqx-link" onClick={() => setShowInfo(true)}>
                            <FontAwesomeIcon icon={faInfoCircle} size="lg"/>
                        </span>
                    </div>
                </div>
                <Modal size="lg" show={showInfo} onHide={() => setShowInfo(false)}>
                    <CSVUploadInfo />
                </Modal>
                <div className="mt-3 pb-5">
                    {uploading && <IqLoadingIcon />}

                    {results && (
                        <div>
                            <Table striped hover>
                                <thead>
                                    <tr>
                                        <th colSpan={3} className="text-center">Match</th>
                                        <th colSpan={2} className="text-center">Input</th>
                                    </tr>
                                    <tr>
                                        <th style={{width: "7%"}} className="text-end">Row</th>
                                        <th style={{width: "43%"}}>Organisation</th>
                                        <th style={{width: "8%"}} className="text-center">Matched</th>
                                        <th>Company Name</th>
                                        <th>Company Number</th>
                                        <th>URL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results.map((result: CSVUploadResult, index: number) => (
                                        <tr key={result.result.companyId}>
                                            <td className="text-end">{index + 1}</td>
                                            <td>
                                                {result.result.companyId ? (
                                                    <AsyncSelect
                                                                 placeholder={translate("advancedsearch.search")}
                                                                 defaultOptions={[]}
                                                                 defaultValue={{
                                                                     label: result.result.companyName,
                                                                     value: result.result.companyId
                                                                 }}
                                                                 onChange={(e: any) => updateOrganisation(e.value, index)}
                                                                 loadOptions={loadOrganisations}/>
                                                ) : (
                                                    <AsyncSelect
                                                                 placeholder={translate("advancedsearch.search")}
                                                                 defaultOptions={[]}
                                                                 onChange={(e: any) => updateOrganisation(e.value)}
                                                                 loadOptions={loadOrganisations}/>
                                                )}
                                            </td>
                                            <td className="text-center">
                                                {!result.result.companyId ? (
                                                    <FontAwesomeIcon icon={faTimesCircle} color="#a82509" />
                                                ) : (
                                                    <FontAwesomeIcon icon={faCheckCircle} color="#2bcb14" />
                                                )}
                                            </td>
                                            <td>
                                                {result.row.companyName}
                                            </td>
                                            <td>
                                                {result.row.companyNumber}
                                            </td>
                                            <td>
                                                {result.row.url}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <div className="mt-2 pull-right">
                                <AddToListButton companyIds={organisations} variant="grey"/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <SweetAlert danger
                        show={error}
                        title="Error!"
                        onConfirm={() => {
                            setError(false);
                            setTimedOut(false);
                        }}
                        onCancel={() => {
                            setError(false);
                            setTimedOut(false);
                        }}>
                {getErrorMessage()}
            </SweetAlert>
        </Container>
    );
};

export default CSVUpload;