import translate, {userLanguage} from "../../i18n/translate";
import Locale from "../../components/common/Locale";
import dateFormat from "dateformat";

const MONTHS = [
    "months.jan",
    "months.feb",
    "months.mar",
    "months.apr",
    "months.may",
    "months.jun",
    "months.jul",
    "months.aug",
    "months.sep",
    "months.oct",
    "months.nov",
    "months.dec"
];

export const getMonthName = (month: number) => {
    return translate(MONTHS[month]);
};

class DateFormatter {
    private static getDateFormat() {
        switch (userLanguage) {
            case Locale.GERMAN.key:
                return "dd.mm.yyyy";
            default:
            case Locale.ENGLISH.key:
                return "dd/mm/yyyy";
        }
    }

    static formatDate(input: string) {
        if (!input) {
            return "-";
        }

        let date = new Date(input);
        return dateFormat(date, this.getDateFormat());
    }

    static formatMillis(input: number) {
        if (!input) {
            return "-";
        }

        let date = new Date(input);
        return dateFormat(date, this.getDateFormat());
    }
}

export default DateFormatter;
