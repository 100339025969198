import {jwtDecode} from "jwt-decode";
import axios from "axios";

export enum Role {
    USER = "ROLE_USER",
    ADMIN = "ROLE_ADMIN",
    TEMPORARY = "ROLE_TEMPORARY",
    CLIENT_ADMIN = "ROLE_CLIENT_ADMIN",
    TRIAL_USER = "ROLE_TRIAL",
    FREEMIUM = "ROLE_FREEMIUM",
    BASIC_ACCOUNT_MANAGER = "ROLE_BASIC_ACCOUNT_MANAGER",
    ADVANCED_ACCOUNT_MANAGER = "ROLE_ADVANCED_ACCOUNT_MANAGER",
    DISCOVERY_ONE = "ROLE_DISCOVERY_TIER_ONE",
    DISCOVERY_TWO = "ROLE_DISCOVERY_TIER_TWO",
    DISCOVERY_THREE = "ROLE_DISCOVERY_TIER_THREE",
    NYLAS_ACCESS = "ROLE_NYLAS",
    HUBSPOT = "ROLE_HUBSPOT",
    CAMPAIGN_RECIPIENT = "ROLE_CAMPAIGN_RECIPIENT",
    CAMPAIGN_VIEWER = "ROLE_CAMPAIGN_VIEWER",
    CAMPAIGN_CREATOR = "ROLE_CAMPAIGN_CREATOR",
    CAMPAIGN_SUPERADMIN = "ROLE_CAMPAIGN_SUPERADMIN",
    TD_COMMUNITIES = "ROLE_TD_COMMUNITIES",
    TD_SALES = "ROLE_TD_SALES",
    TD_RENEWALS = "ROLE_TD_RENEWALS",
    GEO_UK = "ROLE_GEO_UK",
    GEO_DE = "ROLE_GEO_DE",
    GEO_FR = "ROLE_GEO_FR",
    GEO_IT = "ROLE_GEO_IT",
    GEO_RO = "ROLE_GEO_RO",
    GEO_US = "ROLE_GEO_US",
    INTERNAL= "ROLE_INTERNAL",
    ROLE_TDSYNNEX_BASIC = "ROLE_TDSYNNEX_BASIC"
}

export const getUserRoles = (): string[] => {
    let login: string | null = localStorage?.getItem("login");
    let roles: string[] = []
    if (login != null) {
        let token: string = JSON.parse(login).token;
        let decodedToken: { "auth": "" } = jwtDecode(token);
        let allRoles: string = decodedToken["auth"];
        roles = allRoles.split(',');
    }

    return roles;
};

export const getUserImageURL = (): string | null => {
    let login: string | null = localStorage?.getItem("login");
    if (login) {
        let token: string = JSON.parse(login).token;
        let decodedToken: { "imageUrl": "" } = jwtDecode(token);
        return decodedToken["imageUrl"];
    }

    return null;
};

export const getUserFirstName = (): string | null => {
    let login: string | null = localStorage?.getItem("login");
    if (login) {
        let token: string = JSON.parse(login).token;
        let decodedToken: { "firstName": "" } = jwtDecode(token);
        return decodedToken["firstName"];
    }

    return null;
};

export const getUserLastName = (): string | null => {
    let login: string | null = localStorage?.getItem("login");
    if (login) {
        let token: string = JSON.parse(login).token;
        let decodedToken: { "lastName": "" } = jwtDecode(token);
        return decodedToken["lastName"];
    }

    return null;
};

export const getUserUuid = (): string | null => {
    let login: string | null = localStorage?.getItem("login");
    if (login) {
        let token: string = JSON.parse(login).token;
        let decodedToken: { "userUuid": "" } = jwtDecode(token);
        return decodedToken["userUuid"];
    }

    return null;
}

export const getUserLangKey = (): string => {
    let login: string | null = localStorage?.getItem("login");
    if (login) {
        let token: string = JSON.parse(login).token;
        let decodedToken: { "langKey": "" } = jwtDecode(token);
        let langKey = decodedToken["langKey"];
        return langKey ? langKey : "en";
    }

    return "en";
};

export const getUserLoginCount = (): number => {
    let login: string | null = localStorage?.getItem("login");
    if (login) {
        let token: string = JSON.parse(login).token;
        let decodedToken: { "loginCount": "" } = jwtDecode(token);
        let loginCount = decodedToken["loginCount"];
        return loginCount ? loginCount : 0;
    }

    return 0;
};

export const getUserTermsAgreed = (): boolean => {
    let login: string | null = localStorage?.getItem("login");
    let termsAgreed: boolean = false
    if (login != null) {
        let token: string = JSON.parse(login).token;
        let decodedToken: { "termsAgreed": "" } = jwtDecode(token);
        termsAgreed = Boolean(decodedToken["termsAgreed"]).valueOf();
    }

    return termsAgreed;
}

export const getUserNameTuple = (): [string, string] => {
    let login: string | null = localStorage?.getItem("login");
    let firstName: string = "Name"
    let lastName: string = "Surname"
    if (login != null) {
        let token: string = JSON.parse(login).token;
        let decodedToken: { "firstName": "", "lastName": "" } = jwtDecode(token);
        firstName = String(decodedToken["firstName"]).valueOf();
        lastName = String(decodedToken["lastName"]).valueOf();
    }

    return [firstName, lastName];
}

export const getUserMail = (): string => {
    let login: string | null = localStorage?.getItem("login");
    let email: string = "mail@iqblade.com"
    if (login != null) {
        let token: string = JSON.parse(login).token;
        let decodedToken: { "email": "" } = jwtDecode(token);
        email = String(decodedToken["email"]).valueOf();
    }

    return email;
}

export const getUserToken = (): string | null => {
    let login: string | null = localStorage?.getItem("login");
    if (login != null) {
        let token: string = JSON.parse(login).token;
        return token;
    }

    return null;
}

export const getRefreshToken = (): string | null => {
    let login: string | null = localStorage?.getItem("login");
    if (login != null) {
        let token: string = JSON.parse(login).refresh_token;
        return token;
    }

    return null;
}

export const getFingerprint = (): string | null => {
    let fingerprint: string | null = localStorage?.getItem("fingerprint");
    if (fingerprint != null) {
        return fingerprint;
    }

    return null;
}

export const hasUserRole = (role: string): boolean => {
    return getUserRoles().includes(role);
}

export const hasAnyRole = (roles: string[]): boolean => {
    let userRoles = getUserRoles();
    return roles.some(r => userRoles.includes(r));
};

export const setupInterceptors = (setLogged: any) => {
    axios.interceptors.request.use((config) => {
        if (!config.url?.includes("/api/authenticate")) {
            const token = config.url?.includes("/api/refresh-token") ? getRefreshToken() : getUserToken();
            const fingerprint = getFingerprint();
            config.headers.Authorization = "Bearer " + token;
            config.headers['X-IQ-FINGERPRINT'] = fingerprint;
        }
        return config;
    });

    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error.response.status === 401) {
            refreshToken(() => {
                localStorage.removeItem("login");
                setLogged(false);
            }, () => {
                window.location.reload();
            });

        }
        return Promise.reject(error);
    });
}

export const refreshToken = async (handleFailure: any, doAfter: any) => {
    await axios.get('/api/refresh-token')
        .then((response) => {
            if (response.status === 200) {
                localStorage.setItem('login', JSON.stringify({
                    token: response.data.access_token,
                    refresh_token: response.data.refresh_token
                }))
            } else {
                handleFailure();
            }
        }).catch(error => {
            console.log(error.message);
            handleFailure();
        })
        .finally(doAfter);
};

