import React, {useEffect, useReducer, useState} from "react";
import {Badge, Button, Dropdown, Modal} from "react-bootstrap";
import {Form, Slider, Switch} from "antd";
import Select from "react-select";
import './RequestContactsModal.css';
import SweetAlert from "react-bootstrap-sweetalert";
import IqSmallLoadingIcon from "../../common/IqSmallLoadingIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport, faTrash} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {organisationHostname} from "../../../utils/Configuration";
import {uuidv4} from "../../../utils/Generators";
import {ContactRequest} from "../../../model/contacts/ContactRequest";
import {useFilePicker} from "use-file-picker";
import FileDownload from "js-file-download";
import {hasAnyRole, Role} from "../../../utils/Security";
import ShareContacts from "./ShareContacts";
import {FileContent, UseFilePickerError} from "use-file-picker/types";
import CreatableSelect from "react-select/creatable";

interface RequestContactsModalProps {
    showRequestContactsModal: boolean,
    setShowRequestContactsModal: (show: boolean) => void,
    handleClose: () => void,
    handleRequest: (a: ContactRequest) => void,
    error: boolean,
    setError: (b: boolean) => void,
    warning: boolean,
    setWarning: (b: boolean) => void,
    showSuccess: boolean,
    setShowSuccess: (b: boolean) => void,
    jobTitles: string[],
    loading: boolean
}

enum Types {
    PERSON_TITLES,
    PERSON_LOCATION,
    EXCLUDE_PERSON_LOCATION,
    ORGANISATION_LOCATION,
    EXCLUDE_ORGANISATION_LOCATION
}

const reducer = (state: any, { type, payload }: { type: Types, payload: string[] }) => {
    switch (type) {
        case Types.PERSON_TITLES:
            return { ...state, personTitles: payload };
        case Types.PERSON_LOCATION:
            return { ...state, personLocations: payload };
        case Types.EXCLUDE_PERSON_LOCATION:
            return { ...state, personNotLocations: payload };
        case Types.ORGANISATION_LOCATION:
            return { ...state, organisationLocations: payload };
        case Types.EXCLUDE_ORGANISATION_LOCATION:
            return { ...state, organisationNotLocations: payload };
        default:
            throw new Error(`Unrecognised type [${type}]`);
    }
}
const locationOptions = ["Germany", "United Kingdom", "France"];

const initialState = {
    personTitles: [],
    personLocations: ["United Kingdom"],
    personNotLocations: [],
    organisationLocations: [],
    organisationNotLocations: []
};
const RequestContactsModal: React.FC<RequestContactsModalProps> = (props: RequestContactsModalProps) => {
    const [form] = Form.useForm();

    const [{ personTitles,
        personLocations,
        personNotLocations,
        organisationLocations,
        organisationNotLocations }, dispatch] = useReducer(reducer, initialState);

    const [showSavedFilterDialog, setShowSavedFilterDialog] = useState<boolean>(false);
    const [requestContactCredits, setRequestContactsCredits] = useState<number>(0);
    const [loadingRequestContactCredits, setLoadingRequestContactsCredits] = useState<boolean>(false);
    const [limit, setLimit] = useState<number>(0);
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [fetched, setFetched] = useState<boolean>(false);
    const [exactMatch, setExactMatch] = useState<boolean>(false);

    // Ryan Constants
    const [filterName, setFilterName] = useState<string | undefined>(undefined);

    // Populate fields from Suggestion filter bubble click
    const populateFromSuggestion = (suggestion: any) => {
        populateFromSavedFilter(suggestion?.filters);
        setFilterName(suggestion?.name);
    }

    const populateFromSavedFilter = (filter: Omit<ContactRequest, "listId">) => {
        filter.personTitles && dispatch({ type: Types.PERSON_TITLES, payload: filter.personTitles });
        filter.personLocations && dispatch({ type: Types.PERSON_LOCATION, payload: filter.personLocations });
        filter.personNotLocations && dispatch({ type: Types.EXCLUDE_PERSON_LOCATION, payload: filter.personNotLocations });
        filter.organisationLocations && dispatch({ type: Types.ORGANISATION_LOCATION, payload: filter.organisationLocations });
        filter.organisationNotLocations && dispatch({ type: Types.EXCLUDE_ORGANISATION_LOCATION, payload: filter.organisationNotLocations });
    }

    const resetFilters = () => {
        dispatch({ type: Types.PERSON_TITLES, payload: [] });
        dispatch({ type: Types.PERSON_LOCATION, payload: [] });
        dispatch({ type: Types.EXCLUDE_PERSON_LOCATION, payload: [] });
        dispatch({ type: Types.ORGANISATION_LOCATION, payload: [] });
        dispatch({ type: Types.EXCLUDE_ORGANISATION_LOCATION, payload: [] });
        setExactMatch(false);
        setFilterName("");
    }

    const saveFilters = (name: string | undefined) => {
        if (name) {
            setSuggestions([...suggestions, {
                name: name,
                uuid: uuidv4(),
                filters: {
                    personTitles: personTitles,
                    personLocations: personLocations,
                    personNotLocations: personNotLocations,
                    organisationLocations: organisationLocations,
                    organisationNotLocations: organisationNotLocations
                }
            }])
            setFilterName(name);
        } else {
            setShowSavedFilterDialog(true);
        }
    }

    const deleteFilter = (uuid: string) => {
        setSuggestions(suggestions.filter(suggestion => suggestion.uuid !== uuid))
    }

    const persistFilters = async () => {
        await axios.post(organisationHostname + 'organisation/request-contacts-filters', JSON.stringify(suggestions), {
            headers: { 'Content-Type': 'text/plain' }
        }).then(r => { }).catch(error => console.log(error.message));
    }

    const exportJson = async (name: string, filters: string) => {
        const blob = new Blob([filters], { type: "text/json" });

        const a = document.createElement("a");
        a.download = `${name}.json`;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    }

    const exportCsv = async (name: string, filters: string) => {
        const csvRows = writeCsvRows(filters);
        const fileName = `${name}.csv`;
        FileDownload(csvRows, fileName);
    }

    const writeCsvRows = (filters: string) => {
        let parsedFilters = JSON.parse(filters);
        let keys = Object.keys(parsedFilters);
        let header = keys.join(",");

        let maxRows = Math.max(
            ...keys.map(k => (parsedFilters[k].length || 0))
        );

        let result = header + "\n";
        for (let i = 0; i < maxRows; i++) {
            let row = [];
            for (let key of keys) {
                let entries = parsedFilters[key];
                if (entries.length > i) {
                    row.push(entries[i]);
                }
                else {
                    row.push(null);
                }
            }
            result += row.join(",");
            result += "\n";
        }

        return result;
    }

    const {openFilePicker, filesContent, errors, loading, plainFiles, clear} = useFilePicker({
        multiple: false,
        accept: ['.json', '.csv']
    });

    useEffect(() => {
        if (filesContent && filesContent.length > 0) {
            let file = filesContent[0];
            if (!file) {
                errors.push({name: "Error uploading file", plainFile: file} as UseFilePickerError);
            }

            if (file.name.indexOf(".csv") !== -1) {
                importCsv(file);
            }
            else {
                importJson(file);
            }

            clear();
        }
    }, [filesContent]);

    const importCsv = async (file: FileContent<string>) => {
        let filter = processCsvToJson(file.content);
        populateFromSavedFilter(filter);
        let fileName = file.name.replace(".csv", "");
        importFilter(fileName, filter);
    }

    const importJson = async (file: FileContent<string>) => {
        let filter = JSON.parse(file.content) as ContactRequest;
        populateFromSavedFilter(filter);
        let fileName = file.name.replace(".json", "");
        importFilter(fileName, filter);
    }

    const processCsvToJson = (csv: string) => {
        let lines = csv.split("\n");
        let headers = lines[0].split(",").map(value => value.replaceAll("\r", ""));
        let json = {};

        for (let header of headers) {
            //@ts-ignore
            json[header] = [];
        }

        for(let i = 1; i < lines.length; i++){
            let line = lines[i].split(",");
            for(let j = 0; j < headers.length; j++){
                let h = headers[j];
                let value = line[j]?.replaceAll("\r", "");
                if (value) {
                    //@ts-ignore
                    json[h] = [...json[h], line[j]];
                }
            }
        }

        return json as ContactRequest;
    }

    const importFilter = (name: string, filter: ContactRequest) => {
        let importedFilters = {
            name: name,
            uuid: uuidv4(),
            filters: {
                personTitles: filter.personTitles,
                personLocations: filter.personLocations,
                personNotLocations: filter.personNotLocations,
                organisationLocations: filter.organisationLocations,
                organisationNotLocations: filter.organisationNotLocations
            }
        }

        setSuggestions([...suggestions, importedFilters]);
        setFilterName(name);
    }

    const fetchFilters = async () => {
        await axios.get(organisationHostname + 'organisation/request-contacts-filters')
            .then(r => { setSuggestions(r.data); setFetched(true); })
            .catch(error => console.log(error.message))
    }

    const fetchRequestContactsCredits = async () => {
        setLoadingRequestContactsCredits(true);
        await axios.get(organisationHostname + "organisation/request-contacts-credits")
            .then(r => setRequestContactsCredits(r.data.capacity - r.data.consumed))
            .catch(error => console.error(error.message))
            .finally(() => setLoadingRequestContactsCredits(false));
    };

    useEffect(() => {
        if (fetched) {
            persistFilters();
        }
    }, [suggestions])

    useEffect(() => {
        fetchFilters();
        fetchRequestContactsCredits();
    }, [])

    return (
        <Modal show={props.showRequestContactsModal} onHide={props.handleClose} dialogClassName="modal-dialog modal-full-height modal-right modal-notify modal-success">
            <Modal.Header closeButton>
                <Modal.Title>Request contacts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    form={form}
                    layout="vertical"
                    className="request-contacts-form">
                    <Form.Item>
                        <div className={"row"}>
                            <div className={"col"}>
                                <h5>Saved Filters</h5>
                            </div>
                            <div className={"col"}>
                                <div>
                                    <button className="iqx-button primary sm-size pull-right" onClick={() => openFilePicker()}>Import filters</button>
                                    {loading && <IqSmallLoadingIcon />}
                                </div>
                            </div>
                        </div>

                        <div className="pt-2"/>
                        {suggestions.length > 0 && suggestions.map((suggestion: any) =>
                            <h5>
                            <div className="mt-1 row">
                                    <div className={"col"}>
                                        <Badge onClick={() => populateFromSuggestion(suggestion)} style={{ cursor: "pointer" }} bg="primary">
                                            {suggestion?.name}{' '}
                                        </Badge>
                                    </div>
                                    <div className={"col"} style={{ justifyContent: "flex-end", textAlign: "right" }}>
                                        <Dropdown className="ms-2" as="span">
                                            <Dropdown.Toggle id="export" className="export-toggle" variant="link" as="span" size="sm">
                                                <FontAwesomeIcon title="Export" style={{ marginLeft: 2, marginRight: 8, cursor: "pointer" }} color="#4aaad6" size="xs" icon={faFileExport} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="mt-1" align="end">
                                                <Dropdown.Item>
                                                    <a className="btn btn-link" onClick={() => exportJson(suggestion.name, JSON.stringify(suggestion.filters))}>Export Configuration</a>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <a className="btn btn-link" onClick={() => exportCsv(suggestion.name, JSON.stringify(suggestion.filters))}>Export Template</a>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {hasAnyRole([Role.ADMIN, Role.CLIENT_ADMIN]) && (
                                            <ShareContacts filters={suggestion} />
                                        )}
                                        <FontAwesomeIcon title="Delete" onClick={() => deleteFilter(suggestion?.uuid)} style={{ marginLeft: 2, marginRight: 8, cursor: "pointer" }} icon={faTrash} color="lightcoral" size="xs" />
                                    </div>
                            </div>
                            </h5>
                        )}

                        {(suggestions.length === 0 && plainFiles.length === 0) && (
                            <span>You do not have any saved filters.</span>
                        )}
                    </Form.Item>
                    {loadingRequestContactCredits ? <IqSmallLoadingIcon /> :
                        <Form.Item label={["Limit search to ", limit ? limit : "X", " credits out of possible ", requestContactCredits].join("")}>
                            <Slider value={limit} onChange={(val: number) => { setLimit(val) }}
                                max={requestContactCredits} />
                        </Form.Item>
                    }

                    {/* Filter Name */}
                    <Form.Item label="Filter Name">
                        <input
                            disabled={false}
                            autoFocus={true}
                            type="text"
                            className="input-modal-box"
                            key={"widget-multiselect"}
                            placeholder={"Input Filter Name"}
                            value={filterName}
                            onChange={e =>  setFilterName(e.target.value)}>
                        </input>
                    </Form.Item>

                    {/* Job Titles */}
                    <Form.Item label="Person Job Titles">
                        <CreatableSelect isMulti
                                         options={props.jobTitles.sort((a: string, b: string) => a.localeCompare(b)).map(opt => ({label: opt, value: opt}))}
                                         value={personTitles.map(t => ({value: t, label: t}))}
                                         onChange={(val: any) => {
                                             let payload = val.map(v => v.value);
                                             dispatch({ type: Types.PERSON_TITLES, payload: payload })
                                         }}
                                         placeholder="Input job titles"
                                         className="basic-multi-select"
                                         classNamePrefix="select"
                        />
                    </Form.Item>
                    <Form.Item label="Person Locations">
                        <Select isMulti
                                options={locationOptions.map(opt => ({value: opt, label: opt}))}
                                value={personLocations.map(t => ({value: t, label: t}))}
                                onChange={(val: any) => {
                                    let payload = val.map(v => v.value);
                                    dispatch({ type: Types.PERSON_LOCATION, payload: payload })
                                }}
                                placeholder="Input person locations"
                                className="basic-multi-select"
                                classNamePrefix="select"
                        />
                    </Form.Item>
                    <Form.Item label="Exclude Person Locations">
                        <Select isMulti
                                options={locationOptions.map(opt => ({value: opt, label: opt}))}
                                value={personNotLocations.map(t => ({value: t, label: t}))}
                                onChange={(val: any) => {
                                    let payload = val.map(v => v.value);
                                    dispatch({ type: Types.EXCLUDE_PERSON_LOCATION, payload: payload })
                                }}
                                placeholder="Exclude person locations"
                                className="basic-multi-select"
                                classNamePrefix="select"
                        />
                    </Form.Item>
                    <Form.Item label="Exact Match">
                        <Switch checked={exactMatch}
                                onChange={(value: boolean) => setExactMatch(value)}/>
                    </Form.Item>
                </Form>
                <hr />
            </Modal.Body>
            <Modal.Footer>
                {props.loading ? <span style={{ color: "white" }}><IqSmallLoadingIcon /></span> :
                    <>
                        <Button variant={"secondary"} onClick={() => resetFilters()}>
                            Clear
                        </Button>
                        <Button variant="secondary" onClick={props.handleClose}>
                            Close
                        </Button>
                        {suggestions.length < 3 && <Button variant="secondary" onClick={() => saveFilters(filterName)}>
                            Save filters
                        </Button>}
                        <Button variant="primary" onClick={() => props.handleRequest({
                            limit: limit,
                            personTitles: personTitles,
                            personLocations: personLocations,
                            personNotLocations: personNotLocations,
                            organisationLocations: organisationLocations,
                            organisationNotLocations: organisationNotLocations,
                            exactMatch: exactMatch
                        })}>
                            Request
                        </Button>
                    </>
                }
            </Modal.Footer>
            <SweetAlert danger
                show={props.error}
                title="An error has occurred!"
                onConfirm={() => props.setError(false)}
                onCancel={() => props.setError(false)}>
                Please try again later.
            </SweetAlert>

            <SweetAlert warning
                show={props.warning}
                title="Consumption exceeded!"
                onConfirm={() => props.setWarning(false)}
                onCancel={() => props.setWarning(false)}>
                You don't have enough credits left to request contacts.
            </SweetAlert>

            <SweetAlert warning
                cancelBtnBsStyle="light"
                title="Error! Add a name to your saved filter!"
                show={showSavedFilterDialog}
                onConfirm={() => setShowSavedFilterDialog(false)}
            >
            </SweetAlert>

            <SweetAlert success
                show={props.showSuccess}
                title="Success!"
                onConfirm={() => { props.setShowSuccess(false); props.setShowRequestContactsModal(false) }}>
                Contacts successfully requested. Requests may take a while to process depending on the number of contacts requested, we will notify you when your request has been processed.
            </SweetAlert>
        </Modal>
    );
};

export default RequestContactsModal;
