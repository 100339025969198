import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Image, InputGroup} from 'react-bootstrap';
import UserOrganisationContext from '../../../context/organisation/OrganisationContext';
import axios from 'axios';
import {organisationHostname} from '../../../utils/Configuration';
import SweetAlert from 'react-bootstrap-sweetalert';
import IqSmallLoadingIcon from '../../common/IqSmallLoadingIcon';
import Select from 'antd/lib/select';
import OrganisationTag from '../../../model/organisation/OrganisationTag';

const Option = Select.Option;

const OrganisationEditorDetails: React.FC = () => {


    const { organisation, setOrganisation, fetchCompany } = useContext(UserOrganisationContext);

    interface OrganisationDetails {
        id: string,
        introduction: string,
        website: string,
        primaryType: string,
        logoUrl: string,
        tradingAs: string,
        tags: OrganisationTag[],
        competencies: string[]
    }

    const [details, setDetails] = useState<OrganisationDetails>({} as OrganisationDetails);
    const [error, setError] = useState<boolean>(false);
    const [reset, setReset] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setDetails({
            id: organisation.id,
            introduction: organisation.companyIntroduction,
            website: organisation.website,
            primaryType: organisation.primaryType,
            tradingAs: organisation.tradingAs,
            logoUrl: organisation.imageUrl,
            tags: organisation.tags,
            competencies: organisation.competencies

        })
    }, [organisation, reset]);

    const [competencyOptions, setCompetencyOptions] = useState<string[]>();
    useEffect(() => {
        const fetchCompetencies = async () => {
            await axios.get(organisationHostname + "competencies")
                .then(r => setCompetencyOptions(r.data))
        };

        fetchCompetencies();
    }, []);

    const handleDetailsChange = (event: any) => {
        setDetails({ ...details, [event.target.name]: event.target.value });
    };

    const handlePrimaryTypeChange = (val: string) => {
        setDetails({ ...details, primaryType: val })
    }

    const getTagArray = (tags: OrganisationTag[]) => (v: string): OrganisationTag => {
        const foundTag = tags.find(t => t.name === v);
        if (foundTag !== undefined) {
            return foundTag;
        } else {
            return {
                id: null,
                name: v,
                lastModifiedBy: "User",
                lastModifiedDate: null,
                organisationId: organisation.id
            } as OrganisationTag
        }
    }

    const handleTagsChange = (val: string[]) => {
        let tagArray: OrganisationTag[] = val.map(getTagArray(details.tags))

        setDetails({ ...details, tags: tagArray })
    }

    const handleCompetenciesChange = (competencies: string[]) => {
        setDetails({
            ...details,
            competencies: competencies
        });
    };

    const updateDetails = (e: any) => {
        e.preventDefault();
        setLoading(true);
        axios.post(organisationHostname + 'organisation/' + organisation.id + '/edit/details', details)
            .then((response) => {
                fetchCompany()
                setSuccess(true)
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }

    const updateWithClearbit = (e: any) => {
        e.preventDefault();
        setLoading(true);
        axios.post(organisationHostname + 'organisation/' + organisation.id + '/edit/clearbit')
            .then((response) => {
                fetchCompany()
                setSuccess(true)
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }

    const primaryTypeOptions = [
        'EndUser',
        'Unknown',
        'Vendor',
        'Outsourcer',
        'Analyst',
        'ISV',
        'Recruitment',
        'Retailer',
        'ProfessionalServices',
        'Telco',
        'DigitalAgency',
        'Distributor',
        'Reseller',
        'ManagedServices',
        'Media & Events',
        'InvestmentFinance',
        'MSP',
        'CloudConsultant',
        'EducationProvider',
        'Technology Park / Incubator',
        'LeadGeneration',
        'PublicSector'
    ]


    return (
        <div className="organisation-card">
            <div className="iq-tx-11 iq-tx-uppercase iq-tx-spacing-1 iq-tx-semibold iq-tx-color-02">
                Organisation Details
                                    </div>
            <Image
                style={{
                    height: "125px",
                    width: "125px",
                    objectFit: "cover",
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginBottom: 15,
                    border: "2px solid #5FD1F9"
                }}
                src={details.logoUrl}
                roundedCircle />
            <Form>

                <Form.Group controlId="formLogo">
                    <Form.Label>Logo URL</Form.Label>
                    <Form.Control name="logoUrl" type="text"
                        onChange={handleDetailsChange}
                        value={details.logoUrl} />
                </Form.Group>

                <Form.Group controlId="formIntroduction">
                    <Form.Label>Introduction</Form.Label>
                    <Form.Control as="textarea"
                        style={{ height: 100 }}
                        name="introduction"
                        value={details.introduction}
                        onChange={handleDetailsChange}>
                        {/* {organisation.companyIntroduction} */}
                    </Form.Control>

                </Form.Group>
                <Form.Group controlId="formTradingAs">
                    <Form.Label>Trading As</Form.Label>
                    <Form.Control name="tradingAs" type="text"
                                  onChange={handleDetailsChange}
                                  value={details.tradingAs} />
                </Form.Group>

                <Form.Group controlId="formWebsite">
                    <Form.Label>Website</Form.Label>
                    <Form.Control name="website" type="text"
                        onChange={handleDetailsChange}
                        value={details.website} />
                </Form.Group>
                <Form.Group controlId="formPBT">
                    <Form.Label>Primary Business Type</Form.Label>
                    {/*<Form.Row>*/}
                        <Select
                            disabled={false}
                            style={{
                                minWidth: 200,
                                // width: 200,
                                width: "100%"
                            }}
                            dropdownStyle={{
                                width: 250,
                            }}
                            // onDropdownVisibleChange={this.handleDropdownOpen}
                            key={"widget-multiselect"}
                            placeholder={"Select primary business type"}
                            size={"large"}
                            value={details.primaryType}
                            onChange={(val: string) => { handlePrimaryTypeChange(val) }}
                            filterOption={true}
                        // maxTagCount={1}
                        >
                            {primaryTypeOptions.map(o => {
                                return (<Option key={o} value={o}>{o}</Option>);
                            })}
                        </Select>
                    {/*</Form.Row>*/}
                </Form.Group>
                <Form.Group controlId="formPBT">
                    <Form.Label>Tags</Form.Label>
                    {/*<Form.Row>*/}
                        <Select
                            disabled={false}
                            mode={'tags'}
                            style={{
                                minWidth: 200,
                                // width: 200,
                                width: "100%"
                            }}
                            dropdownStyle={{
                                width: 250,
                            }}
                            // onDropdownVisibleChange={this.handleDropdownOpen}
                            key={"widget-multiselect"}
                            placeholder={"Input tags"}
                            size={"large"}
                            value={details.tags?.map(t => t.name)}
                            onChange={(val: string[]) => { handleTagsChange(val) }}
                            filterOption={true}
                        // maxTagCount={1}
                        />
                    {/*</Form.Row>*/}
                </Form.Group>

                <Form.Group controlId="formCompetencies">
                    <Form.Label>Competencies</Form.Label>
                    {/*<Form.Row>*/}
                        <Select
                            disabled={false}
                            mode={'tags'}
                            style={{
                                minWidth: 200,
                                width: "100%"
                            }}
                            dropdownStyle={{
                                width: 250,
                            }}
                            key={"widget-multiselect"}
                            placeholder={"Input competencies"}
                            size={"large"}
                            value={details.competencies}
                            options={competencyOptions?.map(opt => ({value: opt, label: opt}))}
                            onChange={(val: string[]) => { handleCompetenciesChange(val) }}
                            filterOption={true}
                        />
                    {/*</Form.Row>*/}
                </Form.Group>
                <Form.Group controlId="formPBT">
                    <Form.Label>Industry Classification</Form.Label>
                    {/*<Form.Row>*/}
                        <InputGroup className="mb-3">

                            <Form.Control name="sector" type="text" disabled={true}
                                value={organisation.industryClassification.sector?.name || "Unknown"} />
                            <Form.Control name="industryGroup" type="text" disabled={true}
                                value={organisation.industryClassification.industryGroup?.name || "Unknown"} />
                            <Form.Control name="industry" type="text" disabled={true}
                                value={organisation.industryClassification.industry?.name || "Unknown"} />
                            <Form.Control name="subIndustry" type="text" disabled={true}
                                value={organisation.industryClassification.subIndustry?.name || "Unknown"} />
                        </InputGroup>
                    {/*</Form.Row>*/}
                    {/*<Form.Row>*/}
                        <i>* Industry classification editable only through auto-gatherer (due to data integrity concerns)</i>
                    {/*</Form.Row>*/}
                </Form.Group>
                {loading ? <IqSmallLoadingIcon />
                    :
                    <>
                        <Button style={{ marginLeft: "auto", marginTop: 15 }}
                            variant="primary"
                            type="submit"
                            onClick={(e: any) => updateDetails(e)}>
                            Save
                        </Button>
                        <Button style={{ marginLeft: 10, marginTop: 15 }}
                            variant="secondary"
                            type="submit"
                            onClick={(e: any) => { e.preventDefault(); setReset(!reset); }}>
                            Reset
                        </Button>
                    </>
                }
            </Form>
            <SweetAlert danger
                show={error}
                title="An error has occurred!"
                onConfirm={() => setError(false)}
                onCancel={() => setError(false)}>
                Please try again later.
                            </SweetAlert>

            <SweetAlert success
                show={success}
                title="Success!"
                onConfirm={() => setSuccess(false)}
                onCancel={() => setSuccess(false)}>
                Details saved successfully!
        </SweetAlert>
        </div>
    );
}

export default OrganisationEditorDetails;
