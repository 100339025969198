import React, {useEffect, useState} from 'react';
import './Campaigns.css';
import {Alert, Col, Container, Row} from 'react-bootstrap';
import axios from "axios";
import {socialHostName} from "../../utils/Configuration";
import IqLoadingIcon from "../common/IqLoadingIcon";
import translate from "../../i18n/translate";
import Campaign from '../../model/campaigns/Campaign';
import {Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import CampaignView from './CampaignView';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBullhorn, faCalendar, faChevronLeft, faChevronRight, faPowerOff} from '@fortawesome/free-solid-svg-icons';
import CampaignContext from '../../context/campaign/CampaignContext';

const LinkedinCampaigns: React.FC = () => {

    let match = useRouteMatch();
    let history = useHistory();

    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const fetchCampaigns = async () => {
            setIsLoading(true);
            await axios.get(socialHostName + "linkedin/campaigns")
                .then(r => {
                    setCampaigns(r.data as Campaign[]);
                })
                .catch(error => console.error(error.message))
                .finally(() => setIsLoading(false));
        };

        fetchCampaigns();
    }, []);

    return (
        <Container fluid style={{ marginTop: 15 }}>
            <CampaignContext.Provider value={{ campaigns, setCampaigns, isLoading, setIsLoading }}>

                <Switch>
                    <Route exact path={`${match.url}/`}>
                        {isLoading ? <IqLoadingIcon /> : (
                            error ?
                                <Alert className="mt-2" variant="danger">
                                    {translate("errors.loading")}
                                </Alert>
                                :
                                <>
                                    <Row style={{marginBottom: 15}}>
                                        <Col>
                                            <button className="iqx-button iconic md-size pe-2 pe-3"
                                                onClick={() => history.goBack()}>
                                                <span className="pe-2"><FontAwesomeIcon icon={faChevronLeft} /></span>
                                                <span>Back</span>
                                            </button>
                                        </Col>
                                    </Row>

                                    <div className="iq-headline">
                                        <span className="text">
                                            {translate("campaigns.title")}
                                        </span>
                                    </div>

                                    <Row className="" style={{ marginLeft: 0, marginRight: 0 }}>
                                        <div id="campaign-list-container">
                                            {
                                                campaigns
                                                    .map(entry => (
                                                        <div className="campaign-card">
                                                            <div>
                                                                <span className="campaign-magnifier-box">
                                                                    <FontAwesomeIcon icon={faBullhorn} color="white" size="1x" />
                                                                </span>
                                                                <span className="name">
                                                                    {entry.campaign.name}
                                                                </span>
                                                            </div>
                                                            <div className="campaign-content-container">
                                                                <div className="campaign-details-container">
                                                                    <div className="campaign-detail">
                                                                        <div className="campaign-detail-container">
                                                                            <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 10 }} className="icon" icon={faCalendar} color="black" size="1x" /></span>
                                                                            <span>Started on {entry.startDay + "/" + entry.startMonth + "/" + entry.startYear}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="campaign-detail">
                                                                        <div className="campaign-detail-container">
                                                                            <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 10 }} className="icon" icon={faPowerOff} color="black" size="1x" /></span>
                                                                            <span>{entry.campaign.status}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="campaign-actions">
                                                                <div className="campaign-action">
                                                                    <button className="iqx-button sm-size primary mb-2 pull-right"
                                                                        // disabled={hasExpired(search)}
                                                                        onClick={() => history.push("/campaigns-manager/linkedin/" + entry.campaign.id)}>
                                                                        <span style={{ paddingRight: 10 }}>
                                                                            View Analytics
                                                                        </span>
                                                                        <FontAwesomeIcon icon={faChevronRight} size="lg" />
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))
                                            }
                                        </div>
                                    </Row>
                                </>
                        )}
                    </Route>
                    {console.log(match.url)}
                    <Route exact path={`${match.url}/:urn`}>
                        <CampaignView />
                    </Route>
                </Switch>
            </CampaignContext.Provider>
        </Container>
    );
};

export default LinkedinCampaigns;