import axios from "axios";
import React from "react";
import { useContext, useState } from "react";
import {Button, Col, Modal, Row } from "react-bootstrap";
import translate from "../../../i18n/translate";
import { socialHostName } from "../../../utils/Configuration";
import {getUserUuid, hasAnyRole, Role} from "../../../utils/Security";
import {Form, Select, Slider} from "antd";
import UserOrganisationContext from "../../../context/organisation/OrganisationContext";
import IqSmallLoadingIcon from "../../common/IqSmallLoadingIcon";
import SweetAlert from "react-bootstrap-sweetalert";
import { JobRequest } from "../../../model/organisation/JobRequest";
import {matchPath, useLocation } from "react-router-dom";
import UserListContext from "../../../context/list/UserListContext";

const ListOrganisationJobModal: React.FC = () => {

    let location = useLocation()
    const childMatchedPath: any = matchPath(location.pathname, {
        path: '/lists/:listId',
        exact: false,
        strict: false
    });

    const { setShowJobsModal, showJobsModal } = useContext(UserListContext)


    const [form] = Form.useForm();
    const { organisation } = useContext(UserOrganisationContext);
    const { list } = useContext(UserListContext);

    const [error, setError] = useState<boolean>(false);
    const [warning, setWarning] = useState<boolean>(false);

    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [jobTitle, setJobTitle] = useState<string>("")
    const [jobLocation, setJobLocation] = useState<string>("")
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

    const handleListRequest = (e?: any) => {

        if (e) {
            e.preventDefault();
        }

        let params: any = {
            userUuId: getUserUuid()
        }

        let request: JobRequest =
            {
                jobTitle: jobTitle,
                companyName: "",
                countryCode: "",
                organisationId: ""
            } as JobRequest

        axios.post(socialHostName + "jobs/request-list/" + childMatchedPath?.params?.listId, request, {params: params});
        setShowJobsModal(false)

    };

    const isOrganisationGotWebsite = () => {
        if (!organisation.website || organisation.website.trim() === ""){
            return true
        }
        return false
    }

    return (
        <>
            <Modal show={showJobsModal} onHide={() => setShowJobsModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Request Jobs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        form={form}
                        layout="vertical"
                        className="request-contacts-form">
                        <Form.Item label="Job Title">
                            <input
                                disabled={false}
                                autoFocus={true}
                                type="text"
                                className="input-modal-box"
                                placeholder={"Input Job Title"}
                                value={jobTitle}
                                onChange={e =>  setJobTitle(e.target.value)}>
                            </input>
                        </Form.Item>
                        {loading ? <span style={{ color: "white" }}><IqSmallLoadingIcon /></span> :
                            <Button variant="primary" onClick={() => {
                                handleListRequest()
                            }}>
                                Request
                            </Button>
                        }
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ListOrganisationJobModal;