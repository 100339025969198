import React, {useEffect, useState} from 'react';
import './AdvancedSearchList.css'
import {Alert, Col, Container, Row} from 'react-bootstrap';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import AdvancedSearch from '../../model/advanced-search/AdvancedSearch';
import SweetAlert from 'react-bootstrap-sweetalert';
import {searchHostname} from '../../utils/Configuration';
import IqLoadingIcon from '../common/IqLoadingIcon';
import translate from "../../i18n/translate";
import AdvancedSearchItem from './AdvancedSearchItem';

interface Props {
}

const AdvancedSearchList: React.FC<Props> = (props: Props) => {

    let history = useHistory();

    const [searches, setSearches] = useState<AdvancedSearch[]>([]);
    const [changeOccured, setChangeOccured] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    interface DeletePayload {
        show: boolean,
        searchId: number
    }

    interface RerunPayload {
        show: boolean,
        searchId: number
    }

    interface GenericPayload {
        show: boolean,
        message: string,
        success: boolean
    }

    const [showDelete, setShowDelete] = useState<DeletePayload>({ show: false, searchId: 0 });
    const [showRerun, setShowRerun] = useState<RerunPayload>({ show: false, searchId: 0 });
    const [showGeneric, setShowGeneric] = useState<GenericPayload>({ show: false, message: "", success: false })

    useEffect(() => {
        setLoading(true);
        const fetchSearches = async () => {
            const result = await axios.get(searchHostname + 'advanced-search')
                .then((response) => {
                    setSearches(response.data);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        };
        fetchSearches();
    }, [changeOccured]);


    const onDelete = (searchId: number) => {

        axios.delete(searchHostname + 'advanced-search/' + searchId).then((response) => {
            if (response.status === 200) {
                setChangeOccured(!changeOccured);
                setShowDelete({ show: false, searchId: 0 })
            }
        }).catch(error => console.log(error.message));
    };

    const onRerun = (searchId: number) => {
        setShowRerun({ show: false, searchId: 0 })
        axios.post(searchHostname + 'advanced-search/' + searchId + '/rerun').then((response) => {
            if (response.status === 200) {
                setChangeOccured(!changeOccured);
                setShowRerun({ show: false, searchId: 0 })
                setShowGeneric({ show: true, message: "We have rerun your search!", success: true })
            }
        }).catch(error => {
            if (error.response?.data?.errorKey === "nocredit") {
                setShowGeneric({ show: true, message: "No search credits left.", success: false })
            } else {
                setShowGeneric({ show: true, message: translate("errors.generic"), success: false })
            }
        });
    };



    return (
        <Container className="advanced-search-container" fluid style={{ marginTop: 15 }}>
            <Row className="" style={{ marginLeft: 0, marginRight: 0 }}>
                <Col>
                    <div className="iq-headline"><span className="text">{translate("advancedsearch.title")}</span></div>
                </Col>
                <Col className="text-end">
                    <span style={{ marginLeft: "auto" }}>
                        <button onClick={() => { history.push("/advanced-search/create") }} className="iqx-button md-size dark-blue">
                            <span style={{ paddingRight: 10 }}>{translate("advancedsearch.new")}</span>
                            <FontAwesomeIcon icon={faSearch} color="white" size="1x" />
                        </button>
                    </span>
                </Col>
            </Row>
            {loading ? <IqLoadingIcon /> : (
                error ?
                    <Alert className="mt-2" variant="danger">
                        {translate("errors.loading")}
                    </Alert>
                    :
                    <>
                        <div id="adv-search-list-container">
                            {
                                searches
                                    .map(search => (
                                        <AdvancedSearchItem search={search} setShowDelete={setShowDelete} setShowRerun={setShowRerun} />
                                    ))
                            }
                        </div>
                        <SweetAlert warning
                            showCancel
                            confirmBtnText={translate("lists.confirmdelete")}
                            confirmBtnBsStyle="danger"
                            title={translate("lists.deleteconfirmtitle")}
                            onConfirm={() => onDelete(showDelete.searchId)}
                            onCancel={() => setShowDelete({ show: false, searchId: 0 })}
                            show={showDelete.show}
                            focusCancelBtn>
                            {translate("lists.deleteconfirm", { title: searches.find(s => s.id === showDelete.searchId)?.name })}
                        </SweetAlert>

                        <SweetAlert warning
                            showCancel
                            confirmBtnText={translate("advancedsearch.rerun")}
                            confirmBtnBsStyle="warning"
                            title={translate("lists.deleteconfirmtitle")}
                            onConfirm={() => onRerun(showRerun.searchId)}
                            onCancel={() => setShowRerun({ show: false, searchId: 0 })}
                            show={showRerun.show}
                            focusCancelBtn>
                            {translate("advancedsearch.rerunconfirm")}
                        </SweetAlert>
                        <SweetAlert success={showGeneric.success}
                            danger={!showGeneric.success}
                            show={showGeneric.show}
                            title={showGeneric.success === true ? "Success!" : "There was a problem."}
                            onConfirm={() => setShowGeneric({ show: false, message: "", success: false })}
                        >
                            {showGeneric.message}
                        </SweetAlert>
                    </>
            )}
        </Container>
    );
}

export default AdvancedSearchList;
