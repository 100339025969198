import {faList} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Creatable from "react-select/creatable";
import List from "../../../model/list/List";
import {frontEndHostName} from "../../../utils/Configuration";
import IqLoadingIcon from "../../common/IqLoadingIcon";
import translate from "../../../i18n/translate";
import Select from "react-select";


interface AddToListButton {
    companyIds: string[],
    variant: string,
    buttonText?: string
    advancedSearchId?: number
    advancedSearchName?: string
}

interface ListOption {
    value: string,
    label: string,
    disabled?: boolean
}

const AddToListButton: React.FC<AddToListButton> = (props: AddToListButton) => {

    const [showListAddition, setShowListAddition] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [showCapacityReached, setShowCapacityReached] = useState<boolean>(false);
    const [listExists, setListExists] = useState<boolean>(false);
    const [isCampaignList, setIsCampaignList] = useState<boolean>(false);


    const AddToListForm: React.FC = () => {

        const [userLists, setUserLists] = useState<List[]>([]);
        const [listOptions, setListOptions] = useState<ListOption[]>([]);
        const [selectedList, setSelectedList] = useState<ListOption | null>(null);
        const [hasListCapacity, setListCapacity] = useState<boolean>(true);
        const [hasCustomersCapacity, setCustomersCapacity] = useState<boolean>(false);
        const [hasCompetitorsCapacity, setCompetitorsCapacity] = useState<boolean>(false);
        const [hasCreateCapacity, setCreateCapacity] = useState<boolean>(true);
        const [userListsLoading, setUserListsLoading] = useState<boolean>(false);
        const [showCreateCapacityReached, setShowCreateCapacityReached] = useState<boolean>(false);

        const handleSelectedListChange = (option: ListOption) => {
            setListExists(false);
            setIsCampaignList(false);
            setShowCapacityReached(false);
            setSelectedList(option);
        };

        const [isAddingToList, setAddingToList] = useState<boolean>(false);
        const handleAddCompanyToList = () => {
            if (selectedList) {
                let value = selectedList.value;
                let existingList = userLists.find((list: List) => list.id === Number(value));
                if (existingList) {
                    setAddingToList(true);
                    axios.put(frontEndHostName + 'user-lists/' + existingList.id + '/add-companies',
                        props.companyIds,
                        {
                            params: {
                                advId: props.advancedSearchId,
                                advName: props.advancedSearchName
                            },
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        })
                        .then((response) => {
                            if (response.status === 200) {
                                setShowSuccess(true);
                                setSelectedList(null);
                            } else {
                                setError(true);
                            }
                        })
                        .catch((error) => {
                            let responseData = error.response.data;
                            if (responseData.errorKey === "listexists") {
                                setListExists(true);
                            } else if (responseData.errorKey === "maxcapacity") {
                                setShowCapacityReached(true);
                            } else if (responseData.errorKey === "campaignlist") {
                                setIsCampaignList(true);
                            } else {
                                setError(true);
                                setShowListAddition(false);
                            }
                        })
                        .finally(() => {
                            setShowListAddition(false);
                            setAddingToList(false);
                        });
                } else {
                    let list = {
                        title: selectedList.label,
                        listType: "USER_DEFINED",
                        organisations: props.companyIds
                    };

                    if (hasCreateCapacity) {
                        setAddingToList(true);
                        axios.post(frontEndHostName + "user-lists", list)
                            .then(() => {
                                setShowSuccess(true);
                                setSelectedList(null);
                                setShowListAddition(false);
                            })
                            .catch((error) => {
                                let responseData = error.response.data;
                                if (responseData.errorKey === "listexists") {
                                    setListExists(true);
                                } else if (responseData.errorKey === "maxcapacity") {
                                    setShowCapacityReached(true);
                                } else if (responseData.errorKey === "campaignlist") {
                                    setIsCampaignList(true);
                                } else {
                                    setError(true);
                                    setShowListAddition(false);
                                }
                            })
                            .finally(() => {
                                setAddingToList(false);
                                setShowListAddition(false);
                            });
                    }
                }
            }
        };

        useEffect(() => {
            if (showListAddition) {
                setUserListsLoading(true);
                const fetchLists = async () => {
                    await axios.get(frontEndHostName + "user-lists")
                        .then(r => {
                            setUserLists(r.data as List[]);
                            setListOptions(r.data.map((list: List) => {
                                return {
                                    value: `${list.id}`,
                                    label: translate("organisation.addtolist.listlabel", {
                                        title: list.title,
                                        count: list.organisations.length
                                    })
                                }
                            }));
                        })
                        .catch(error => console.error(error.message))
                        .finally(() => {
                            setUserListsLoading(false);
                        });
                };
                fetchLists();
            }
        }, [showListAddition]);

        useEffect(() => {
            const getListCapacity = async () => {
                await axios.get(frontEndHostName + "has-list-capacity?count=" + props.companyIds.length)
                    .then(r => setListCapacity(r.data))
                    .catch(() => setListCapacity(false))
            };
            getListCapacity();
        }, [hasListCapacity]);

        useEffect(() => {
            const getCustomersCapacity = async () => {
                await axios.get(frontEndHostName + "has-customers-capacity?count=" + props.companyIds.length)
                    .then(r => setCustomersCapacity(r.data))
                    .catch(() => setCustomersCapacity(false))
            };
            getCustomersCapacity()
        }, [hasCustomersCapacity]);

        useEffect(() => {
            const getCompetitorsCapacity = async () => {
                await axios.get(frontEndHostName + "has-competitors-capacity?count=" + props.companyIds.length)
                    .then(r => setCompetitorsCapacity(r.data))
                    .catch(() => setCompetitorsCapacity(false))
            };
            getCompetitorsCapacity();
        }, [hasCompetitorsCapacity]);

        useEffect(() => {
            const updateListOptions = () => {
                if (userLists) {
                    let updatedOptions: ListOption[] =
                        userLists.map((list: List) => {
                            let listType = list.listType;
                            let isDisabled = (!hasCustomersCapacity && listType === 'CUSTOMERS')
                                || (!hasCompetitorsCapacity && listType === 'COMPETITORS')
                                || (!hasListCapacity && listType === 'USER_DEFINED');

                            return {
                                value: `${list.id}`,
                                label: translate("organisation.addtolist.listlabel", {
                                    title: list.title,
                                    count: list.organisations.length
                                }),
                                disabled: isDisabled
                            } as ListOption;
                        });
                    setListOptions(updatedOptions);
                }
            };
            updateListOptions();
        }, [userLists, hasListCapacity, hasCompetitorsCapacity, hasCustomersCapacity]);

        const [loadingCreateCapacity, setLoadingCreateCapacity] = useState<boolean>(false);
        useEffect(() => {
            const getCreateCapacity = () => {
                setLoadingCreateCapacity(true);
                axios.get(frontEndHostName + "has-create-capacity")
                    .then(r => setCreateCapacity(r.data))
                    .finally(() => setLoadingCreateCapacity(false));
            };
            getCreateCapacity();
        }, []);

        return (<>
            <Modal show={showListAddition} onHide={() => setShowListAddition(false)}
                   dialogClassName="modal-dialog modal-full-height modal-right modal-notify modal-success">
                <Modal.Header closeButton>
                    <Modal.Title>{props.companyIds?.length == 1 ? translate("organisation.addtolist.subtitle") : translate("organisation.addtolist.multiplesubtitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(userListsLoading || loadingCreateCapacity) ? <IqLoadingIcon/> : (
                        <Form.Group style={{marginTop: 15}}>
                            {props.companyIds.length >= 1000 ?  <Form.Label>Note: 1,000 companies will be added to this list, if you require more please get in touch for a bespoke operation</Form.Label> : null}
                            <Form.Label>{translate("organisation.addtolist.chooselist")}</Form.Label>
                            {hasCreateCapacity ? (
                                <Creatable onChange={(e: any) => handleSelectedListChange(e)}
                                           placeholder={translate("organisation.addtolist.listplaceholder")}
                                           options={listOptions}
                                           isOptionDisabled={(option: any) => option.disabled}/>
                            ) : (
                                <Select options={listOptions}
                                        isOptionDisabled={(option: any) => option.disabled}
                                        onChange={(e: any) => handleSelectedListChange(e)}
                                        placeholder={translate("organisation.addtolist.selectplaceholder")}
                                        className="basic-multi-select"
                                        classNamePrefix="select"/>
                            )}
                        </Form.Group>
                    )}

                    {!hasListCapacity && (
                        <Alert variant="warning">
                            {translate("organisation.addtolist.maxcapacity")}
                        </Alert>
                    )}

                    {!hasCreateCapacity && (
                        <Alert variant="warning">
                            {translate("organisation.addtolist.maxlists")}
                        </Alert>
                    )}

                    {isAddingToList && (
                        <IqLoadingIcon/>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowListAddition(false)}>
                        {translate("dashboard.close")}
                    </Button>
                    <Button variant="success"
                            disabled={selectedList === null}
                            onClick={() => handleAddCompanyToList()}>
                        {props.companyIds?.length == 1 ? translate("organisation.addtolist.title") : translate("organisation.addtolist.multipletitle")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>)
    };

    return (
        <>
            <button type="button"
                    onClick={() => setShowListAddition(true)}
                    className={"iq-action-button m-1 " + props.variant} id="addToListButton">
                <div className="organisation-button-text">
                    {props.companyIds?.length == 1 ? (
                        <span className="text">
                            {translate("organisation.addtolist.title")}
                        </span>
                    ) : (

                        props.buttonText ? (
                            <span className="text me-3">
                                {props.buttonText}
                            </span>
                        ) : (
                            <span className="text me-3">
                                {translate("organisation.addtolist.multipletitle")}
                            </span>
                        )

                    )}
                </div>
                <FontAwesomeIcon className="icon ms-1" style={{marginTop: "2px"}} icon={faList} color="#2F6FC3" size="1x"/>
            </button>
            {showListAddition && <AddToListForm/>}
            <SweetAlert danger
                        show={error}
                        title="An error has occurred!"
                        onConfirm={() => setError(false)}
                        onCancel={() => setError(false)}>
                <span style={{color: "black"}}>{translate("errors.generic")}</span>
            </SweetAlert>

            <SweetAlert danger
                        show={showCapacityReached}
                        title="Maximum capacity reached."
                        onConfirm={() => setShowCapacityReached(false)}
                        onCancel={() => setShowCapacityReached(false)}>
                <span style={{color: "black"}}>{translate("organisation.addtolist.maxcapacity")}</span>
            </SweetAlert>

            <SweetAlert danger
                        show={listExists}
                        title="List already exists."
                        onConfirm={() => setListExists(false)}
                        onCancel={() => setListExists(false)}>
                <span style={{color: "black"}}>{translate("organisation.addtolist.duplicatename")}</span>
            </SweetAlert>

            <SweetAlert danger
                        show={isCampaignList}
                        title="List is frozen."
                        onConfirm={() => setIsCampaignList(false)}
                        onCancel={() => setIsCampaignList(false)}>
                <span style={{color: "black"}}>{translate("organisation.addtolist.frozen.campaign")}</span>
            </SweetAlert>

            <SweetAlert success
                        show={showSuccess}
                        title="Success!"
                        onConfirm={() => setShowSuccess(false)}
                        onCancel={() => setShowSuccess(false)}>
                <span style={{color: "black"}}>{translate("organisation.addtolist.addsuccess")}</span>
            </SweetAlert>
        </>
    );
};

export default AddToListButton;
