import React, {useState} from "react";
import List from "../../model/list/List";
import DateFormatter from "../../utils/formatters/DateFormatter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBuilding,
    faCalendar,
    faChevronRight,
    faList,
    faShare,
    faTrashAlt,
    faUser,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "react-bootstrap-sweetalert/dist";
import axios from "axios";
import {frontEndHostName} from "../../utils/Configuration";
import {useHistory} from "react-router-dom";
import translate from "../../i18n/translate";
import ListShare from "./ListShare";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {getUserUuid} from "../../utils/Security";

interface Props {
    list: List,
    ribbonText?: string,
    deleteList?: (id: number) => void
}

const ListLink: React.FC<Props> = (props: Props) => {
    const [list, setList] = useState<List>(props.list);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [isCampaignList, setIsCampaignList] = useState<boolean>(false);
    const [notListCreator, setNotListCreator] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [isBeingShared, setBeingShared] = useState<boolean>(false);

    const history = useHistory();

    const deleteList = () => {
        axios.delete(frontEndHostName + "user-lists/" + list.id)
            .then(() => deleteSuccessHandler())
            .catch((error) => errorHandler(error));
    };

    const deleteSuccessHandler = () => {
        setShowDelete(false);
        setShowSuccess(true);
    };

    const errorHandler = (error: any) => {
        let responseData = error.response.data;
        setShowDelete(false);
        if (responseData.errorKey === "campaignlist") {
            setIsCampaignList(true);
        } else if (responseData.errorKey === "notlistcreator") {
            setNotListCreator(true);
        } else {
            setShowError(true);
        }
    };

    const accept = () => {
        if (props.deleteList !== undefined) {
            props.deleteList(list.id);
            setShowSuccess(false);
            setShowDelete(false);
        }
    };

    const getRibbonClass = () => {
        let className = "ribbon ribbon-top-right ";
        if (props.ribbonText === "lists.shared") {
            className += "ribbon-orange";
        }
        else {
            className += "ribbon-blue";
        }

        return className;
    };

    return (
        <>
            <div className="list-card">
                <div>
                    <span className="list-icon-box">
                        <FontAwesomeIcon icon={faList} color="white" size="1x" />
                    </span>
                    <span className="name" id="list-name" style={{cursor: "pointer"}} onClick={() => document.getElementById("view-list" + list.id)?.click()}>
                        {list.title}
                    </span>
                    {props.ribbonText && (
                        // <div className={getRibbonClass()}><span>{translate(props.ribbonText)}</span></div>
                        <OverlayTrigger overlay={<Tooltip id="radio-tooltip">{props.ribbonText === "lists.shared" ? "Shared" : "Private"}</Tooltip>} delay={{ show: 100, hide: 200 }} placement="right">
                            <span>{props.ribbonText === "lists.shared"
                                ? <FontAwesomeIcon icon={faUsers} color={"#3078c7"} />
                                : <FontAwesomeIcon icon={faUser} color={"#3078c7"} />}</span>
                        </OverlayTrigger>
                    )}
                </div>
                <div className="list-content-container">
                    <div className="list-details-container">
                        <div className="list-detail">
                            <div className="list-detail-container">
                                <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faCalendar} color="#9120C3" size="1x" /></span>
                                <span>{translate("lists.createdon", { date: DateFormatter.formatDate(list.createdOn.toString()) })}</span>
                            </div>
                        </div>
                        <div className="list-detail">
                            <div className="list-detail-container">
                                <span><FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} className="icon" icon={faBuilding} color="#f84899" size="1x" /></span>
                                <span>{translate("lists.companies", { count: list.organisations.length.toLocaleString() })}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list-actions">
                    {props.deleteList && (
                        <div className="list-action">
                            <button className="iqx-button sm-size pull-right" onClick={() => setShowDelete(true)}>
                                <span style={{ paddingRight: 10 }}>
                                    {translate("lists.deletelist")}
                                </span>
                                <FontAwesomeIcon icon={faTrashAlt} size="1x" />
                            </button>
                        </div>
                    )}
                    {list.userUuid === getUserUuid() && <div className="list-action">
                        <button className="iqx-button sm-size primary mb-2 pull-right" onClick={() => setBeingShared(true)}>
                            <span style={{ paddingRight: 10 }}>
                                {"Share"}
                            </span>
                            <FontAwesomeIcon icon={faShare} size="lg" />
                        </button>
                    </div>}
                    <div className="list-action">
                        <button className="iqx-button sm-size primary mb-2 pull-right" id={"view-list" + list.id} onClick={() => history.push(`/lists/${list.id}`)}>
                            <span style={{ paddingRight: 10 }}>
                                {translate("lists.viewlist")}
                            </span>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                        </button>
                    </div>
                </div>
                {isBeingShared && <ListShare list={list} setList={setList} setBeingShared={setBeingShared} />}
                {!isBeingShared && list?.shares?.length > 0 && <div className={"list-shared-with"}>Shared by <span className={"team"}>{list.userDisplayName}</span> with {list.shares
                    .map<React.ReactNode>(s => <span className={"team"}>{s.name}</span>)}</div>}
            </div>
            <SweetAlert warning
                showCancel
                confirmBtnText={translate("lists.confirmdelete")}
                confirmBtnBsStyle="danger"
                title={translate("lists.deleteconfirmtitle")}
                onConfirm={() => deleteList()}
                onCancel={() => setShowDelete(false)}
                show={showDelete}
                focusCancelBtn>
                {translate("lists.deleteconfirm", { title: list.title })}
            </SweetAlert>
            <SweetAlert success
                show={showSuccess}
                title={translate("lists.deletesuccess")}
                onConfirm={() => accept()}
                onCancel={() => accept()}>
            </SweetAlert>
            <SweetAlert danger
                show={showError}
                title={translate("lists.deletefailed")}
                onConfirm={() => setShowError(false)}
                onCancel={() => setShowError(false)}>
                {translate("errors.generic")}
            </SweetAlert>
            <SweetAlert danger
                show={isCampaignList}
                title={translate("errors.generic")}
                onConfirm={() => setIsCampaignList(false)}
                onCancel={() => setIsCampaignList(false)}>
                {translate("lists.deletefailedcampaignlist")}
            </SweetAlert>
            <SweetAlert danger
                show={notListCreator}
                title={"Unable to delete."}
                onConfirm={() => setNotListCreator(false)}
                onCancel={() => setNotListCreator(false)}>
                {"You are not the owner of the list."}
            </SweetAlert>
        </>
    );
};

export default ListLink;
